/**
 * Plans APIs
 */

import { INSURANCE_TYPES } from "../constants";
import httpClient from "../utils/HttpUtils";

export const PlansApis = {
  getPlans: async (payload) => {
    const { insurance_type = INSURANCE_TYPES.ivf, riders_only = 0 } =
      payload ?? {};

    try {
      const resp = await httpClient.get(
        `/plans?&filters[insurance_type]=${insurance_type}&filters[riders_only]=${riders_only}`
      );
      return resp;
    } catch (error) {
      console.log("Error getPlans", error);
    }
  },

  getPlanById: async ({ plan_id }) => {
    try {
      const resp = await httpClient.get(`plans/${plan_id}`);
      return resp;
    } catch (error) {
      console.log("Error getPlanById", error);
    }
  },

  updatePlan: async ({ plan_id, params }) => {
    try {
      const resp = await httpClient.put(`plans/${plan_id}`, params);
      return resp;
    } catch (error) {
      console.log("Error updatePlan", error);
    }
  },
};
