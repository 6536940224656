/*
 * Custom hook to fetch reproductive or disability riders (only for SAD)
 * based on term length and specific rider (if provided)
 */

import { useState, useEffect, useCallback } from "react";

import { PlansApis } from "../api";
import { INSURANCE_TYPES } from "../constants";

export const usePlansWithRiders = (insuranceType) => {
  const [riderPlans, setRiderPlans] = useState(null);

  useEffect(() => {
    const fetchRiderPlans = async () => {
      if (!riderPlans) {
        const params = { insurance_type: insuranceType, riders_only: 1 };
        const resp = await PlansApis.getPlans(params);
        setRiderPlans(resp?.data ?? []);
      }
    };

    fetchRiderPlans();
  }, [riderPlans, insuranceType]);

  const getRiders = useCallback(
    (termLength, specificRider) => {
      if (!riderPlans || !termLength) {
        return [];
      }
      const filteredDataByTerms = riderPlans?.filter?.(
        (i) => i?.term_length === termLength
      );
      const filteredData =
        insuranceType === INSURANCE_TYPES.ivf
          ? filteredDataByTerms
          : filteredDataByTerms?.filter?.((i) => i?.group === specificRider);
      const resultArray = filteredData
        ?.map?.((i) => ({ label: i?.description, value: i?.key_code }))
        ?.sort?.((a, b) => a?.value?.localeCompare?.(b?.value));

      return resultArray;
    },
    [riderPlans]
  );

  const getRiderLabelByValue = useCallback(
    (termLength, value, specificRider) => {
      const riders = getRiders(termLength, specificRider);
      const selectedOption = riders?.find?.((item) => item.value === value);
      return selectedOption?.label || "";
    },
    [getRiders]
  );

  return { getRiders, getRiderLabelByValue };
};
