/**
 * Policy Action APIs
 */

import httpClient from "../utils/HttpUtils";

export const ActionApis = {
  updatePolicyAction: async ({ application_id, action, formData }) => {
    try {
      const resp = await httpClient.put(
        `applications/${application_id}/actions/${action}`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      return resp;
    } catch (error) {
      console.log("Error updatePolicyAction", error);
    }
  },
};
