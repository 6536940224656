/*
 * Admin -> User accounts -> Edit User
 */

import React, { useEffect, useState, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Box, Grid, InputAdornment, Badge, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import {
  Accordion,
  AddressInput,
  AgencyDetails,
  ClinicDetails,
  CommonModal,
  Dropdown,
  GlobalLoader,
  GoBack,
  MandatoryStatement,
  PhoneInput,
  TextInput,
  Tooltip,
} from "../../components";
import {
  UtilFunctions,
  emailRegex,
  focusFirstErrorField,
  testNonLetter,
} from "../../utils/CommonUtils";
import { AttachmentApis, UsersApis } from "../../api";
import logo from "../../layouts/images/user.svg";
import { AdminPageLayout } from "../../layouts/admin-layout/AdminPageLayout";
import { USER_ROLES } from "../../constants";
import { SadActionButtons } from "../sad/sad-admin/components/ActionButtons";

const { agency, clinic } = USER_ROLES;

const iconStyles = {
  width: 25,
  height: 25,
  padding: "3px",
  fontSize: "small",
  color: "white",
  cursor: "pointer",
  backgroundColor: "#C0580E",
  borderRadius: "50%",
};

const formValues = {
  profile_pic_attachment_id: "",
  profile_pic_attachment_url: "",
  first_name: "",
  last_name: "",
  mobile: "",
  address_line_1: "",
  zipcode: "",
  country_code: "US",
  state_code: "",
  city: "",
};

const generalFormValues = {
  display_name: "",
  role: null,
  agency_name: null,
  agency_country_code: "US",
  agency_state_code: null,
  clinic_name: null,
  clinic_country_code: "US",
  clinic_state_code: null,
};

const EditUser = () => {
  const { agency_id, broker_code_group, clinic_id, user_id } = useParams();
  const navigate = useNavigate();
  const applyNewApplicationButtonRef = useRef(null);
  const lookups = useSelector((state) => state.commonReducer.lookups);
  const [userData, setUserData] = useState({});
  const [isWorkingWithAgency, setWorkingWithAgency] = useState(false);
  const [userRole, setUserRole] = useState("");
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const { user_types_list } = lookups ?? {};
  const isGeneralUser = !agency_id && !broker_code_group && !clinic_id;

  const validationSchema = useMemo(() => {
    const requiredSchema = {
      first_name: Yup.string().required("First Name is required"),
      last_name: Yup.string().required("Last Name is required"),
      email: Yup.string()
        .nullable()
        .required("Email is required")
        .matches(emailRegex, "Invalid email")
        .transform((value, originalValue) =>
          originalValue === "" ? null : value
        ),
      mobile: Yup.string().required("Contact number is required"),
      address_line_1: Yup.string().required("Street Address is required"),
      country_code: Yup.string().required("Country is required"),
      zipcode: Yup.string().required("Zip Code is required"),
      state_code: Yup.string().required("State is required"),
      city: Yup.string().required("City is required"),
    };

    if (isGeneralUser) {
      requiredSchema["role"] = Yup.string().required("Role is required");
    }
    if (
      isGeneralUser &&
      (userRole === agency || (userRole === clinic && isWorkingWithAgency))
    ) {
      requiredSchema["agency_country_code"] = Yup.string().required(
        "Agency Country is required"
      );
      requiredSchema["agency_state_code"] = Yup.string().required(
        "Agency State is required"
      );
      requiredSchema["agency_name"] = Yup.string().required(
        "Agency Name is required"
      );
    }

    if (isGeneralUser && userRole === clinic) {
      requiredSchema["clinic_country_code"] = Yup.string().required(
        "Clinic Country is required"
      );
      requiredSchema["clinic_state_code"] = Yup.string().required(
        "Clinic State is required"
      );
      requiredSchema["clinic_name"] = Yup.string().required(
        "Clinic Name is required"
      );
      requiredSchema["working_with_agency"] = Yup.string().required(
        "Working With Agency is required"
      );
    }

    return Yup.object(requiredSchema);
  }, [isWorkingWithAgency, userRole]);

  const {
    clearErrors,
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    reset,
    setError,
    setValue,
    watch,
  } = useForm({
    defaultValues: isGeneralUser
      ? { ...formValues, ...generalFormValues }
      : formValues,
    resolver: yupResolver(validationSchema),
  });

  const hasPicture = watch("profile_pic_attachment_url");
  const isRoleAgency = watch("role") === agency;
  const isRoleClinic = watch("role") === clinic;

  useEffect(() => {
    const errorArray = Object.keys(errors);
    if (!errorArray?.length) return;
    focusFirstErrorField();
  }, [errors]);

  useEffect(() => {
    getUserData();
  }, []);

  const setDataInitially = () => {
    if (Object.entries?.(userData)?.length) {
      const { role } = userData ?? {};
      const {
        mobile,
        country_code,
        agency: agencyDetails,
        clinic: clinicDetails,
        working_with_agency,
        ...rest
      } = userData ?? {};

      const {
        name: agency_name,
        country_code: agency_country_code,
        state_code: agency_state_code,
      } = agencyDetails ?? {};
      const {
        name: clinic_name,
        country_code: clinic_country_code,
        state_code: clinic_state_code,
      } = clinicDetails ?? {};

      Object.keys(rest).forEach((key) => {
        if (getValues()?.hasOwnProperty(key)) {
          setValue(key, rest[key]);
        }
      });

      setValue("mobile", mobile);
      setValue("country_code", country_code || "US");
      setUserRole(role);

      if (working_with_agency !== null) {
        setValue("working_with_agency", working_with_agency);
        setWorkingWithAgency(working_with_agency);
      }
      if (
        role === agency ||
        (role === clinic && watch("working_with_agency"))
      ) {
        setValue("working_with_agency", 1);
        setValue("agency_country_code", agency_country_code || "US");
        setValue("agency_state_code", agency_state_code || null);
        setValue("agency_name", agency_name);
      }
      if (role === clinic) {
        setValue("clinic_country_code", clinic_country_code || "US");
        setValue("clinic_state_code", clinic_state_code || null);
        setValue("clinic_name", clinic_name);
      }

      clearErrors();
    }
  };

  useEffect(() => {
    setDataInitially();
  }, [userData]);

  const getUserData = async () => {
    setLoading(true);
    const resp = await UsersApis.getUserById({ user_id });
    (!isGeneralUser || (!isRoleAgency && !isRoleClinic)) && setLoading(false);
    resp?.success && setUserData(resp?.data ?? {});
  };

  const onSubmitForm = async () => {
    setButtonLoading(true);
    let payload = getValues();

    payload = UtilFunctions.deleteKeys(payload, [
      "profile_pic_attachment_id",
      "profile_pic_attachment_url",
      "email",
      "role",
      "clinic_country_code",
      "clinic_state_code",
      "clinic_name",
      "agency_country_code",
      "agency_state_code",
      "agency_name",
    ]);

    if (agency_id) {
      payload = { ...payload, agency_id };
    } else if (broker_code_group) {
      payload = { ...payload, broker_code_group };
    } else if (clinic_id) {
      payload = UtilFunctions.deleteKeys(payload, [
        "agency_id",
        "working_with_agency",
      ]);
      payload = { ...payload, clinic_id };
    } else if (isGeneralUser) {
      if (isRoleAgency) {
        payload = UtilFunctions.deleteKeys(payload, ["clinic_id"]);
      }
      if (isRoleClinic && !isWorkingWithAgency) {
        payload = UtilFunctions.deleteKeys(payload, ["agency_id"]);
      }
    }

    const resp = await UsersApis.updateUser({ user_id, params: payload });

    setButtonLoading(false);
    if (resp?.success) {
      setSuccessMessage(true);
      applyNewApplicationButtonRef?.current?.focus();
    } else {
      Object.keys(resp?.errors).forEach(function (key) {
        setError(key, { message: resp?.errors[key] });
      });
    }
  };

  const uploadProfilePicture = async (file) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("labelled_as", "USER");
    formData.append("file", file);
    const resp = await AttachmentApis.uploadAttachment({
      formData,
    });
    !resp?.success && setLoading(false);

    if (resp?.success && resp?.data) {
      const { id, url } = resp?.data ?? {};
      setValue("profile_pic_attachment_id", id);
      setValue("profile_pic_attachment_url", url);
      const updatePicResp = await UsersApis.updateUser({
        user_id,
        params: {
          profile_pic_attachment_id: id,
          profile_pic_attachment_url: url,
        },
      });
      !!updatePicResp && setLoading(false);
    }
  };

  // Function to handle user image upload
  const handleImageUpload = (event) => {
    // Check if a file is selected
    if (event.target.files && event.target.files.length > 0) {
      const uploadedImage = event.target.files[0];
      setValue(
        "profile_pic_attachment_url",
        URL.createObjectURL(uploadedImage)
      );
      uploadProfilePicture(uploadedImage);
    } else {
      setValue("profile_pic_attachment_url", null);
    }
  };

  const handleUploadClick = (event) => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      document.getElementById("imageUpload").click();
    }
  };

  return (
    <AdminPageLayout>
      {loading && <GlobalLoader loading={loading} />}

      <Box display="flex" alignItems="center">
        <GoBack />
        <Typography variant="h1" sx={{ margin: "0" }}>
          Edit User
        </Typography>
      </Box>

      <Grid container sx={{ paddingTop: "0.7rem" }}>
        <Grid
          item
          xl={12}
          md={12}
          lg={12}
          sm={12}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            display: "flex",
          }}
        >
          <label htmlFor="imageUpload">
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              badgeContent={
                <span
                  role="button"
                  tabindex="0"
                  className="btnProfileImageUpload"
                  disabled={buttonLoading}
                  onClick={handleUploadClick}
                  onKeyDown={handleUploadClick}
                  aria-label={
                    hasPicture ? "Edit User Image" : "Upload User Image"
                  }
                >
                  <Tooltip
                    title={hasPicture ? "Edit User Image" : "Upload User Image"}
                  >
                    {hasPicture ? (
                      <EditOutlinedIcon sx={iconStyles} />
                    ) : (
                      <FileUploadOutlinedIcon sx={iconStyles} />
                    )}
                  </Tooltip>
                </span>
              }
            >
              <img
                src={hasPicture || logo}
                alt="Profile picture"
                height="119"
                width="119"
                style={{
                  borderRadius: "50%",
                  cursor: buttonLoading ? "default" : "pointer",
                  objectFit: "cover",
                  border: !hasPicture && "3px solid #919298",
                }}
              />
            </Badge>
          </label>
          {/* Input for user to upload their image */}
          <Box sx={{ display: "none" }}>
            <input
              type="file"
              id="imageUpload"
              disabled={buttonLoading}
              onChange={handleImageUpload}
              accept="image/*"
            />
          </Box>
          <p id="msgFileUpload" role="alert" aria-live="polite"></p>
        </Grid>

        <form onSubmit={handleSubmit(onSubmitForm)}>
          <Box sx={{ marginLeft: "0.55rem", marginTop: "1.3rem" }}>
            <MandatoryStatement />
          </Box>
          <Grid
            container
            sx={{ justifyContent: "center" }}
            rowSpacing={2}
            columnSpacing={3}
          >
            <Grid
              item
              xl={isGeneralUser ? 4 : 3}
              lg={isGeneralUser ? 4 : 3}
              md={6}
              sm={12}
              xs={12}
            >
              <TextInput
                inputKey="first_name"
                inputLabel="First Name"
                register={register}
                disabled={buttonLoading}
                isError={errors?.first_name?.message}
                errorMsg={errors?.first_name?.message}
              />
            </Grid>
            <Grid
              item
              xl={isGeneralUser ? 4 : 3}
              lg={isGeneralUser ? 4 : 3}
              md={6}
              sm={12}
              xs={12}
            >
              <TextInput
                inputKey="last_name"
                inputLabel="Last Name"
                register={register}
                disabled={buttonLoading}
                isError={errors?.last_name?.message}
                errorMsg={errors?.last_name?.message}
              />
            </Grid>
            {isGeneralUser && (
              <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                <TextInput
                  inputKey="display_name"
                  inputLabel="Display Name"
                  disabled={buttonLoading}
                  register={register}
                  required={false}
                  inputProps={{ maxLength: 50 }}
                  isError={errors?.display_name?.message}
                  errorMsg={errors?.display_name?.message}
                />
              </Grid>
            )}
            <Grid
              item
              xl={isGeneralUser ? 4 : 3}
              lg={isGeneralUser ? 4 : 3}
              md={6}
              sm={12}
              xs={12}
            >
              <TextInput
                inputKey="email"
                inputLabel="Email"
                {...(isGeneralUser && {
                  helperText:
                    "Note: Copy of Application, Invoice and All other documents will be sent to this email address.",
                })}
                disabled
                register={register}
                isError={errors?.email?.message}
                errorMsg={errors?.email?.message}
                endAdornment={
                  <InputAdornment position="end">
                    <CheckCircleIcon sx={{ color: "#00AD00" }} />
                  </InputAdornment>
                }
              />
            </Grid>
            <Grid
              item
              xl={isGeneralUser ? 4 : 3}
              lg={isGeneralUser ? 4 : 3}
              md={6}
              sm={12}
              xs={12}
            >
              <PhoneInput
                inputKey="mobile"
                inputLabel="Contact No."
                autoComplete="off"
                disabled={buttonLoading}
                register={register}
                {...(testNonLetter(watch("mobile")) && {
                  value: watch("mobile"),
                })}
                registerChange={(_, val) => {
                  setValue("mobile", val);
                  !!errors?.mobile?.message && setError("mobile", null);
                }}
                isError={errors?.mobile?.message}
                errorMsg={errors?.mobile?.message}
              />
            </Grid>
            {isGeneralUser && (
              <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                <Dropdown
                  label="User's role in the Submission process?"
                  id="role"
                  disabled
                  options={user_types_list ?? []}
                  selectValue="id"
                  selectLabel="name"
                  register={register}
                  value={watch("role")}
                  isError={errors?.role?.message}
                  errorMsg={errors?.role?.message}
                  helperText="Register as an Agency or IVF Clinic if user is an organization, or as the Intended Parent if user is not an organization."
                />
              </Grid>
            )}
            <AddressInput
              disabled={buttonLoading}
              setLoading={setLoading}
              register={register}
              setValue={setValue}
              getValues={getValues}
              watch={watch}
              setError={setError}
              errors={errors}
              addressKey="address_line_1"
              countryKey="country_code"
              zipcodeKey="zipcode"
              stateKey="state_code"
              cityKey="city"
            />
          </Grid>

          {/* Clinic Details */}
          {isGeneralUser && isRoleClinic && (
            <Accordion
              header="Clinic Details"
              component="h2"
              name="clinic-details"
              defaultExpanded={true}
            >
              <ClinicDetails
                disabled={buttonLoading}
                userData={userData}
                register={register}
                watch={watch}
                getValues={getValues}
                setValue={setValue}
                errors={errors}
                setError={setError}
                loading={loading}
                setLoading={setLoading}
                isRoleClinic={isRoleClinic}
              />
            </Accordion>
          )}

          {/* Agency Details */}
          {isGeneralUser && (isRoleAgency || isRoleClinic) && (
            <Accordion
              header="Agency Details"
              component="h2"
              name="agency-details"
              defaultExpanded={true}
            >
              <AgencyDetails
                disabled={buttonLoading}
                userData={userData}
                register={register}
                watch={watch}
                getValues={getValues}
                setValue={setValue}
                errors={errors}
                setError={setError}
                loading={loading}
                setLoading={setLoading}
                isRoleAgency={isRoleAgency}
                isRoleClinic={isRoleClinic}
                setWorkingWithAgency={setWorkingWithAgency}
              />
            </Accordion>
          )}

          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <SadActionButtons
              onCancel={() =>
                isGeneralUser ? setDataInitially() : reset(userData)
              }
              buttonLoading={buttonLoading}
            />
          </Box>
        </form>
      </Grid>

      <CommonModal
        open={successMessage}
        type="success"
        onConfirm={() => {
          setSuccessMessage(false);
          navigate(-1);
        }}
        title="User Updated Successfully!"
      />
    </AdminPageLayout>
  );
};

export { EditUser };
