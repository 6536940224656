/*
 * Static data of whole app
 */

import {
  CoverageWithCovid,
  CoverageWithoutCovid,
} from "../pages/sad/constants";

export const CAPTCHA_KEY = "6LeR75kpAAAAAEFBipp3Wv44KAP2SfS-jMe9UVRk";

export const DEBOUNCE_DELAY = 1000;

export const RESTRICTED_STATES = [
  "AK",
  "AS",
  "FL",
  "GU",
  "KY",
  "MP",
  "NY",
  "PR",
  "UM",
  "VI",
  "WY",
];

export const USER_ROLES = {
  admin: "ADMIN",
  broker: "BROKER",
  ip: "IP",
  agency: "AGENCY",
  clinic: "IVF_CLINIC",
  all: "ALL",
};

export const AttachmentLabels = {
  covid: "COVID",
  editStatus: "EDIT_APPLICATION_STATUS",
};

export const BULK_DOWNLOAD_DOC_TYPES = {
  certificates: "certificates",
  affidavits: "affidavits",
};

export const INSURANCE_TYPES = {
  ivf: "IVF",
  sad: "SAD",
};

export const REQUEST_STATUS = {
  close: "CLOSE",
  open: "OPEN",
};

export const financialEmailTooltip =
  "Up to 3 Email Addresses can be included separated by a; For example: abc@zoho.com; xyz@gmail.com; pqr@gmail.com";

export const natureOfComplicationsTooltip =
  "Please provide details of previous complication including date of occurrence, IVF clinic, protocol used and diagnosis, also sourced details of upcoming cycle protocol; how it will differ to prevent recurrence. Please attach any documents to support this if necessary.";

export const IVF_COVERAGE_TYPES = {
  EDO: "EDO",
  RO: "RO",
  EDR: "EDR",
};

export const coverageTypeLabel = (insurance_type, coverage_type) => {
  if (insurance_type === INSURANCE_TYPES.ivf) {
    if (coverage_type === "EDO") {
      return "Donor Only";
    } else if (coverage_type === "RO") {
      return "Recipient Only";
    } else if (coverage_type === "EDR") {
      return "Donor and Recipient";
    }
  } else if (insurance_type === INSURANCE_TYPES.sad) {
    if (coverage_type === CoverageWithCovid) {
      return "W/ COVID 19";
    } else if (coverage_type === CoverageWithoutCovid) {
      return "W/Out COVID 19";
    }
  }
};

export const typeOfChangeLabel = (request_types_list, type_of_change) => {
  return request_types_list?.filter?.(
    (item) => item.value == type_of_change
  )?.[0]?.label;
};

export const UserRoleLabel = (user_types_list, role) => {
  return user_types_list?.filter?.((item) => item.value == role)?.[0]?.label;
};

export const tableNames = {
  ivfAdmin: "ivf-admin-table",
  ivfBroker: "ivf-broker-table",
  sadAdmin: "sad-admin-table",
  sadBroker: "sad-broker-table",
};

export const orgTypes = {
  verified: "Verified",
  unverified: "Unverified",
  blacklisted: "Blacklisted",
};

export const policyExtensionValues = {
  approved: "APPROVED",
  rejected: "REJECTED",
};

export const policyExtensionLabels = {
  approveLabel: "Approved",
  rejectLabel: "Rejected",
  pendingLabel: "Pending",
};

export const INSURANCE_TYPES_LIST = [
  { label: "IVF", value: INSURANCE_TYPES.ivf },
  { label: "SAD", value: INSURANCE_TYPES.sad },
];

export const coverageTypesList = (insurance_type) => {
  return insurance_type === INSURANCE_TYPES.sad
    ? [
        { label: "W/ COVID 19", value: CoverageWithCovid },
        { label: "W/Out COVID 19", value: CoverageWithoutCovid },
      ]
    : [
        { label: "Donor Only", value: IVF_COVERAGE_TYPES.EDO },
        { label: "Recipient Only", value: IVF_COVERAGE_TYPES.RO },
        { label: "Donor and Recipient", value: IVF_COVERAGE_TYPES.EDR },
      ];
};

export const US_ID = 240;

export const STATE_FEE_LABELS = [
  "Filing Fee",
  "Fire Marshal Tax",
  "MWUA Fee",
  "OK Clearing House Fee",
  "SLSC Charge",
  "Stamping Fee",
  "State Tax",
];

export const PLANS_TYPES_LIST = [
  { label: "Coverage Types", value: 0 },
  { label: "Optional Riders", value: 1 },
];

export const PLANS_TYPES_VALUES = {
  RIDERS_ONLY: 1,
  COVERAGE_ONLY: 0,
};

export const CHARGE_GROUPS = {
  BASE: "BASE",
  DISABILITY_RIDER: "DISABILITY_RIDER",
  REPRODUCTIVE_LOSS_RIDER: "REPRODUCTIVE_LOSS_RIDER",
};

export const ALLOWED_ACTIONS = {
  MARK_REVIEW_IN_PROGRESS: "MARK_REVIEW_IN_PROGRESS",
  MARK_CLEARED: "MARK_CLEARED",
  ISSUE_REISSUE_CERTIFICATE: "ISSUE_REISSUE_CERTIFICATE",
  MARK_CANCELLED: "MARK_CANCELLED",
  REJECT: "MARK_REJECTED",
  PAYNOW: "PAYNOW",
  EXTENSION_REQUEST: "EXTENSION_REQUEST",
  EXTENSION_REQUEST_PAYMENT: "EXTENSION_REQUEST_PAYMENT",
  CHANGE_REQUEST: "CHANGE_REQUEST",
  CANCEL_REQUEST: "CANCEL_REQUEST",
  EDIT_APPLICATION: "EDIT_APPLICATION",
  CONTINUE_SUBMIT_APPLICATION: "CONTINUE_SUBMIT_APPLICATION",
  EMAIL_APPLICATION: "EMAIL_APPLICATION",
  EMAIL_CERTIFICATE: "EMAIL_CERTIFICATE",
  EMAIL_INVOICE: "EMAIL_INVOICE",
  DOWNLOAD_ALL_DOCS: "DOWNLOAD_ALL_DOCS",
  UPLOAD_CERTIFICATE: "UPLOAD_CERTIFICATE",
};

export const SAD_CR_VALIDITY = 90;
