/*
 * Admin -> Wizard -> Coverage Type/Riders -> Edit plan
 */

import React, { useState, useEffect, useMemo } from "react";
import * as Yup from "yup";
import { useParams } from "react-router";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, Typography } from "@mui/material";

import {
  CommonModal,
  GlobalLoader,
  GoBack,
  TextInput,
} from "../../../components";
import { PlansApis } from "../../../api";
import { INSURANCE_TYPES, CHARGE_GROUPS } from "../../../constants";
import { UtilFunctions } from "../../../utils/CommonUtils";
import { SadActionButtons } from "../../sad/sad-admin/components/ActionButtons";
import { AdminPageLayout } from "../../../layouts/admin-layout/AdminPageLayout";

const formValues = {
  premium: "",
  sad_premium_above_40: "",
  policy_plan: "",
  description: "",
};

const EditPlan = () => {
  const navigate = useNavigate();
  const { plan_id } = useParams();
  const [planData, setPlanData] = useState(null);
  const [updateModal, setUpdateModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const isSadPlan = planData && planData?.insurance_type == INSURANCE_TYPES.sad;
  const isBasePlan = planData && planData?.group === CHARGE_GROUPS.BASE;

  const validationSchema = useMemo(() => {
    const requiredSchema = {
      premium: Yup.number()
        .required("Premium amount is required")
        .typeError("Premium must be a number"),
      policy_plan: Yup.string().required("Policy plan is required"),
    };
    if (isSadPlan) {
      requiredSchema["sad_premium_above_40"] = Yup.number()
        .required("Premium amount is required")
        .typeError("Premium must be a number");
    }
    if (!isBasePlan) {
      requiredSchema["description"] = Yup.string().required(
        "Description is required"
      );
    }
    return Yup.object(requiredSchema);
  }, [planData]);

  useEffect(() => {
    const getPlanData = async () => {
      setLoading(true);
      const resp = await PlansApis.getPlanById({ plan_id });
      setLoading(false);
      !!resp?.success && setPlanData(resp?.data);
    };
    !!plan_id && getPlanData();
  }, []);

  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setError,
    setValue,
  } = useForm({
    defaultValues: formValues,
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (plan_id && planData) {
      Object.keys(planData).forEach((key) => {
        if (planData?.hasOwnProperty(key)) {
          setValue(key, planData[key]);
        }
      });
    }
  }, [planData]);

  const onSubmit = async (data) => {
    let payload = {
      policy_plan: data?.policy_plan,
      premium: data?.premium,
      sad_premium_above_40: data?.sad_premium_above_40,
      description: data?.description,
    };

    if (!isSadPlan) {
      payload = UtilFunctions.deleteKeys(payload, ["sad_premium_above_40"]);
    }

    if (isBasePlan) {
      payload = UtilFunctions.deleteKeys(payload, ["description"]);
    }

    setButtonLoading(true);
    const resp = await PlansApis.updatePlan({ plan_id, params: payload });
    setButtonLoading(false);
    if (resp?.success) {
      setUpdateModal(true);
    } else {
      Object.keys(resp?.errors).forEach((key) => {
        setError(key, { message: resp?.errors[key] });
      });
    }
  };

  return (
    <AdminPageLayout>
      <Box sx={{ display: "flex", alignItems: "center", marginBottom: "1rem" }}>
        <GoBack />
        <Typography variant="h1" sx={{ margin: "0" }}>
          Edit Plan
        </Typography>
      </Box>

      {loading && <GlobalLoader loading={loading} />}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container columnSpacing={2} rowSpacing={2}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <TextInput
              inputKey="policy_plan"
              inputLabel="Policy Plan"
              register={register}
              disabled={buttonLoading}
              isError={errors?.policy_plan?.message}
              errorMsg={errors?.policy_plan?.message}
            />
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <TextInput
              inputKey="premium"
              inputLabel={
                isSadPlan ? "Premium (Surrogate age 21-40)" : "Premium"
              }
              register={register}
              disabled={buttonLoading}
              isError={errors?.premium?.message}
              errorMsg={errors?.premium?.message}
            />
          </Grid>

          {!loading && isSadPlan && (
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <TextInput
                inputKey="sad_premium_above_40"
                inputLabel="Sad Premium Above 40"
                register={register}
                disabled={buttonLoading}
                isError={errors?.sad_premium_above_40?.message}
                errorMsg={errors?.sad_premium_above_40?.message}
              />
            </Grid>
          )}

          {!loading && !isBasePlan && (
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <TextInput
                inputKey="description"
                inputLabel="Description"
                register={register}
                disabled={buttonLoading}
                isError={errors?.description?.message}
                errorMsg={errors?.description?.message}
              />
            </Grid>
          )}
        </Grid>

        <SadActionButtons
          onCancel={() => reset(planData)}
          buttonLoading={buttonLoading}
        />
      </form>

      <CommonModal
        open={updateModal}
        type="success"
        onConfirm={() => {
          setUpdateModal(false);
          navigate(-1);
        }}
        title="Plan Updated Successfully!"
      />
    </AdminPageLayout>
  );
};

export { EditPlan };
