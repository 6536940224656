/*
 * ADMIN USERS-ACCOUNTS GRID DATA
 */

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EmailIcon from "@mui/icons-material/Email";
import PlaceIcon from "@mui/icons-material/Place";
import MoreVert from "@mui/icons-material/MoreVert";

import {
  CommonModal,
  GlobalLoader,
  NoRecords,
  RenderActionMenu,
} from "../../../components";
import { AllRoutes } from "../../../routes";
import { theme } from "../../../styles/theme";
import { ImpersonationApis, UsersApis } from "../../../api";
import { UserRoleLabel, USER_ROLES, strings } from "../../../constants";

const RenderCard = ({
  user,
  user_types_list,
  setClickable,
  setSelectedRow,
  setActionAnchorEl,
  setActionLoading,
  setUpdateModal,
  setUserError,
  navigate,
}) => {
  const {
    id,
    profile_pic_attachment_url,
    display_name,
    first_name,
    last_name,
    email,
    email_verified_at,
    address_line_1,
    country_code,
    state_code,
    role,
    agency,
    clinic,
  } = user ?? {};

  const userName = display_name || `${first_name || ""} ${last_name || ""} `;

  const resendEmail = async () => {
    setActionLoading(true);
    const resp = await UsersApis.updateUser({
      user_id: id,
      params: { invite: 1 },
    });
    !!resp && setActionLoading(false);
    !!resp?.success && setUpdateModal(true);
  };

  const loginAsUser = async () => {
    setActionLoading(true);
    const resp = await ImpersonationApis.impersonateUser({ user_id: id });
    if (!resp?.success && resp?.errors) {
      setUserError(resp?.errors?.global);
    }
    setActionLoading(false);
  };

  const openActionDropDown = (e, rowData) => {
    const { id } = rowData ?? {};
    setClickable([
      {
        text: "Edit User",
        route: () => navigate(`${AllRoutes.EditUser.route}/${id}`),
      },
      { text: "Resend Invite Email", route: () => resendEmail() },
      { text: strings.loginAsThisUser, route: () => loginAsUser() },
    ]);
    setSelectedRow(rowData);
    setActionAnchorEl(e.currentTarget);
  };

  return (
    <Card
      sx={{
        display: "flex",
        p: "1rem",
        // backgroundColor: "#F082331A",
        backgroundColor: "#cfc0b51a",
        boxShadow: "0 4px 10px 0 rgba(71, 62, 103, 0.1)",
        border: "1px solid #d4b89f",
        overflow: "hidden",
        clear: "both",
        borderRadius: "5px",
        height: "100%",
        position: "relative",
      }}
    >
      <Badge
        component="div"
        overlap="circular"
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        badgeContent={
          !!email_verified_at && (
            <CheckCircleIcon
              sx={{
                width: 15,
                height: 15,
                color: "#00AD00",
                borderRadius: "50%",
                backgroundColor: "white",
                mb: "1rem",
              }}
            />
          )
        }
      >
        <Avatar
          sx={{ width: 56, height: 56, border: "1px solid #d4b89f" }}
          alt={first_name}
          src={profile_pic_attachment_url}
        />
      </Badge>

      <CardContent sx={{ flex: 2, paddingY: 0 }}>
        <Typography
          component="h2"
          className="fw-bold"
          sx={{ paddingRight: "0.9rem", marginBottom: "0.2rem" }}
        >
          {userName}
        </Typography>
        <Typography
          variant="subtitle2"
          component="div"
          className="text-break"
          sx={{ marginBottom: "0.2rem" }}
        >
          {email && (
            <EmailIcon
              sx={{
                width: 15,
                height: 15,
                verticalAlign: "middle",
                marginRight: "0.3rem",
                color: theme.grey,
              }}
            />
          )}

          {email}
        </Typography>
        <Typography
          variant="subtitle2"
          color={theme.grey}
          component="div"
          className="text-break"
          sx={{ marginBottom: "0.2rem" }}
        >
          {address_line_1 && country_code && state_code && (
            <PlaceIcon
              sx={{
                width: 15,
                height: 15,
                verticalAlign: "middle",
                marginRight: "0.3rem",
              }}
            />
          )}

          {`${address_line_1 || ""} ${
            country_code ? `| ${country_code}` : ""
          } ${state_code ? `| ${state_code}` : ""}`}
        </Typography>
        <Typography
          variant="subtitle2"
          component="div"
          className="text-break"
          sx={{ marginBottom: "0.2rem", display: "flex", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "0.85rem",
              verticalAlign: "middle",
              marginRight: "0.3rem",
              fontWeight: "bold",
            }}
          >
            {`${UserRoleLabel(user_types_list, role)}${
              (role === USER_ROLES.agency && agency?.name) ||
              (role === USER_ROLES.clinic && clinic?.name)
                ? ":"
                : ""
            }`}
          </Typography>
          {role === USER_ROLES.agency
            ? agency?.name || ""
            : role === USER_ROLES.clinic
            ? clinic?.name || ""
            : ""}
        </Typography>
      </CardContent>

      <IconButton
        onClick={(e) => openActionDropDown(e, user)}
        aria-label={"More actions for " + userName}
        sx={{ position: "absolute", top: "2px", right: "2px" }}
      >
        <MoreVert />
      </IconButton>
    </Card>
  );
};

const TableData = ({
  dataList,
  listData,
  page,
  setPage,
  loading,
  dataLoading,
  setSearchParams,
}) => {
  const navigate = useNavigate();
  const lookups = useSelector((state) => state.commonReducer.lookups);
  const [clickable, setClickable] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [actionAnchorEl, setActionAnchorEl] = useState(null);
  const [actionLoading, setActionLoading] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [userError, setUserError] = useState("");
  const { user_types_list } = lookups ?? {};
  const { to, from, total, total_pages } = listData ?? {};

  const closeActionDropDown = () => {
    setSelectedRow(null);
    setClickable([]);
    setActionAnchorEl(null);
  };

  const handlePageChange = (p) => {
    setPage((prev) => {
      setSearchParams((searchParams) => {
        searchParams.set("page", String(p(prev)));
        return searchParams;
      });
      return p(prev);
    });
  };

  if (loading) return <GlobalLoader loading={loading} />;

  return (
    <>
      {dataList?.length > 0 ? (
        <Box>
          <Box
            sx={{
              justifyContent: "flex-end",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Box mx={1} sx={{ marginLeft: "2rem", display: "inline-block" }}>
              {`${from || 0} - ${to || 0}`}
              &nbsp;of {dataList?.length === 0 ? "0" : total}
            </Box>
            <Button
              disabled={dataLoading || page === 1}
              onClick={() => page > 1 && handlePageChange((prev) => prev - 1)}
              style={{ minWidth: 0 }}
              aria-label="Previous"
            >
              {"<"}
            </Button>
            <Button
              disabled={dataLoading || page === total_pages}
              onClick={() =>
                page <= total_pages && handlePageChange((prev) => prev + 1)
              }
              style={{ minWidth: 0 }}
              aria-label="Next"
            >
              {">"}
            </Button>
          </Box>
          <Typography variant="span" className="hidden-visually" role="alert">
            {total} {total === 1 ? "result" : "results"} found.
          </Typography>
          <Grid container spacing={2} sx={{ mt: "10px" }}>
            {dataLoading ? (
              <CircularProgress
                size={28}
                sx={{ position: "absolute", left: { xs: "50%" } }}
              />
            ) : (
              dataList?.map((user) => (
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4} key={user.id}>
                  <RenderCard
                    user={user}
                    user_types_list={user_types_list}
                    setClickable={setClickable}
                    setSelectedRow={setSelectedRow}
                    setActionAnchorEl={setActionAnchorEl}
                    setActionLoading={setActionLoading}
                    setUpdateModal={setUpdateModal}
                    setUserError={setUserError}
                    navigate={navigate}
                  />
                </Grid>
              ))
            )}
          </Grid>

          <RenderActionMenu
            anchorEl={actionAnchorEl}
            handleClose={closeActionDropDown}
            actions={clickable}
            selectedRow={selectedRow}
            sx={{ fontSize: "0.875rem" }}
            anchorOrigin={false}
            transformOrigin={false}
          />
        </Box>
      ) : (
        <NoRecords />
      )}

      {actionLoading && <GlobalLoader loading={actionLoading} />}

      <CommonModal
        open={updateModal}
        type="success"
        onConfirm={() => setUpdateModal(false)}
        title={"Email sent successfully!"}
      />

      <CommonModal
        open={!!userError}
        type="cancel"
        onConfirm={() => setUserError("")}
        title={userError}
      />
    </>
  );
};

export { TableData };
