/*
 * New application page
 */

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  IconButton,
  Popover,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { AllRoutes } from "../../routes";
import { AdminPageLayout } from "../../layouts/admin-layout/AdminPageLayout";
import { theme } from "../../styles/theme";
import ApplyApplication from "../../layouts/images/new_app.svg";
import {
  setIvfDocs,
  setIvfSummary,
  setSadBeneficiaries,
  setSadSummary,
} from "../../redux/slices/globalSlice";

const NewApplication = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const createSADApplicationId = async () => {
    dispatch(setSadSummary({}));
    dispatch(setSadBeneficiaries([]));
    navigate(`${AllRoutes.SadForm.route}`);
  };

  const RenderCard = ({
    image,
    title,
    subTitle,
    doc1Label,
    doc1Link,
    doc2Label,
    doc2Link,
    doc3Label,
    doc3Link,
    onClick,
  }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const downloadOptions = [
      { label: doc1Label, link: doc1Link },
      { label: doc2Label, link: doc2Link },
      { label: doc3Label, link: doc3Link },
    ].filter((option) => option.label && option.link);

    return (
      <Grid
        item
        xl={5}
        lg={6}
        md={7}
        sm={12}
        xs={12}
        sx={{ alignItems: "stretch", display: "flex" }}
      >
        <Card
          sx={{
            borderWidth: 2,
            borderRadius: "6px",
            padding: "1rem",
            marginY: "0.7rem",
            marginX: { xs: "0.1rem", sm: "0.1rem", md: "0.7rem" },
            textAlign: "center",
            "&:hover": {
              boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
              "-webkit-transition": "box-shadow .5s ease-in",
              "-moz-transition": "box-shadow .5s ease-in",
              "-o-transition": "box-shadow .5s ease-in",
              transition: "box-shadow .5s ease-in",
            },
          }}
          variant="outlined"
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body2"
              fontSize="13px"
              sx={{ marginRight: "0.4rem", color: "#C0580E" }}
            >
              Download Samples
            </Typography>
            <IconButton onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
          </Box>
          <img src={image} alt="" style={{ height: "150px", width: "200px" }} />
          <CardContent sx={{ paddingBottom: 0 }}>
            <Typography
              variant="h5"
              component="h2"
              sx={{ fontSize: "1rem", fontWeight: "bold" }}
            >
              {title}
            </Typography>
          </CardContent>
          <CardContent>
            <Typography
              sx={{ textAlign: "center", fontSize: "0.75rem", height: "3rem" }}
              variant="body2"
            >
              {subTitle}
            </Typography>
          </CardContent>
          <Button
            variant="outlined"
            size="medium"
            color="inherit"
            disableFocusRipple
            sx={{
              fontWeight: 550,
              textTransform: "none",
              fontSize: "0.8rem",
              marginBottom: 3,
              borderRadius: "25px",
              color: theme.primaryDark,
              marginTop: "0.5rem",
              background: theme.white,
              borderColor: theme.primaryDark,
              borderWidth: 2,
              "&:hover": {
                backgroundColor: theme.primaryDark,
                color: theme.white,
              },
            }}
            onClick={onClick}
            role="link"
          >
            Apply Application
            <span className="hidden-visually">&nbsp;for {title}</span>
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <List>
              {downloadOptions?.map((option, index) => (
                <ListItem
                  button
                  component="a"
                  href={option.link}
                  download
                  key={index}
                  onClick={handleClose}
                >
                  <ListItemText primary={option.label} />
                </ListItem>
              ))}
            </List>
          </Popover>
        </Card>
      </Grid>
    );
  };

  return (
    <AdminPageLayout>
      <Typography variant="h1">New Application</Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: { xs: "auto", sm: "auto", md: "90%" },
        }}
      >
        <Grid
          container
          sx={{
            textAlign: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <RenderCard
            image={ApplyApplication}
            title="IVF Cycle Complications Insurance Coverage"
            subTitle="Medical Expenses for treatment of the Oocyte Donor and/or Recipient due to Medical Complications related to the cycle as defined within the Policy Certificate."
            doc1Label="Donor Only"
            doc1Link={`${process.env.PUBLIC_URL}/IVF_SAMPLE_DONOR.pdf`}
            doc2Label="Recipient Only"
            doc2Link={`${process.env.PUBLIC_URL}/IVF_SAMPLE_RECIPIENT.pdf`}
            doc3Label="Donor & Recipient"
            doc3Link={`${process.env.PUBLIC_URL}/IVF_SAMPLE_DONOR_RECIPIENT.pdf`}
            onClick={() => {
              dispatch(setIvfSummary([]));
              dispatch(setIvfDocs([]));
              navigate(AllRoutes.IvfCyclePage.route);
            }}
          />
          <RenderCard
            image={ApplyApplication}
            title="Surrogate Accidental Death Insurance"
            subTitle="Indemnity payable in the event of the non-performance of the Contract as a consequence of Accidental death of the Surrogate as defined within the Policy Certificate."
            doc1Label="SAD W/ Covid-19"
            doc1Link={`${process.env.PUBLIC_URL}/SAD_SAMPLE_COVID.pdf`}
            onClick={createSADApplicationId}
          />
        </Grid>
      </Box>
    </AdminPageLayout>
  );
};

export { NewApplication };
