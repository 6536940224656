import React, { useEffect, useState } from "react";
import {
  Accordion,
  AutoComplete,
  CommonModal,
} from "../../../../../components";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useSelector } from "react-redux";
import DropdownIVF from "../common/Dropdown";
import { AgencyApis, CommonApis } from "../../../../../api";
import { useDebounce } from "../../../../../hooks";
import TextInputIvf from "../common/TextInput";
import { AddNewAgency } from "../../../../sad/components/AddNewAgency";
import { useParams } from "react-router-dom";
import { LocalStorageHelper } from "../../../../../utils/HttpUtils";
import { USER_ROLE, USER_ROLES } from "../../../../../constants";
import { constructStateList } from "../../../../../utils/CommonUtils";

const AgencyDetailForm = ({
  index,
  disabled = false,
  register,
  watch,
  setValue,
  errors,
  setError,
  setLoading,
  setUnverifiedOrgModal,
  headingLevel,
}) => {
  const { id: application_id } = useParams();
  const isUserAgency = LocalStorageHelper.get(USER_ROLE) == USER_ROLES.agency;
  const [showAddNewAgency, setShowAddNewAgency] = useState(false);
  const lookups = useSelector((state) => state.commonReducer.lookups);
  const agencies = useSelector((state) => state.ivfReducer.agencies);
  const [agencyList, setAgencyList] = useState(agencies);
  const [stateList, setStateList] = useState([]);
  const [warningAddNewAgency, setWarningAddNewAgency] = useState(false);
  const [isWorkingWithAgency, setIsWorkingWithAgency] = useState();
  const [isBlackListedAgency, setBlackListedAgency] = useState(false);
  const [isNewAgencyAdded, setNewAgencyAdded] = useState(false);

  const isAgencyDisabled = isUserAgency;

  const getStateList = useDebounce(async (value) => {
    const resp = await CommonApis.getZipData({
      country_code: value,
    });
    const getStates = constructStateList(resp);
    setStateList(getStates);
  }, 300);

  useEffect(() => {
    getStateList("US");
  }, []);

  useEffect(() => {
    getAgencyList(watch(`applications.${index}.agency_state_code`));
  }, [isNewAgencyAdded]);

  const getAgencyList = async (value) => {
    const resp = await AgencyApis.getAgenciesList({
      country_code: watch(`applications.${index}.agency_country_code`),
      state_code: value,
    });
    setAgencyList(resp);
  };

  useEffect(() => {
    if (application_id) {
      setIsWorkingWithAgency(
        watch(`applications.${index}.working_with_agency`) || "0"
      );
    } else {
      setIsWorkingWithAgency(
        watch(`applications.${index}.working_with_agency`)
      );
    }
  }, [
    lookups?.yes_no_list,
    watch(`applications.${index}.working_with_agency`),
  ]);

  return (
    <>
      <Accordion
        header="Agency Details"
        component={headingLevel}
        name={`agency-details-${index}`}
      >
        <FormControl sx={{ marginBottom: "1rem" }}>
          <FormLabel
            sx={{
              fontSize: "0.9rem",
              color: "black",
              "&.Mui-focused": {
                color: "black",
              },
            }}
          >
            {isUserAgency
              ? "Which agency you are associated with?"
              : "Are you working with an Agency?"}
          </FormLabel>
          {!isUserAgency && (
            <>
              <RadioGroup row={true} value={isWorkingWithAgency}>
                {lookups?.yes_no_list?.map((item) => {
                  return (
                    <FormControlLabel
                      key={item.value}
                      name={`applications.${index}.working_with_agency`}
                      sx={{ marginRight: "4rem", fontSize: "2" }}
                      componentsProps={{ typography: { variant: "body2" } }}
                      control={<Radio size="small" />}
                      label={item.label}
                      checked={isWorkingWithAgency == item.value}
                      disabled={disabled || isAgencyDisabled}
                      value={item.value}
                      {...register(
                        `applications.${index}.working_with_agency`,
                        {
                          onChange: (e) => {
                            setError(
                              `applications.${index}.working_with_agency`,
                              null
                            );
                            setValue(
                              `applications.${index}.working_with_agency`,
                              e.target.value
                            );
                            setIsWorkingWithAgency(e.target.value);
                          },
                        }
                      )}
                    />
                  );
                })}
              </RadioGroup>
              {errors?.applications?.[index]?.["working_with_agency"]
                ?.message && (
                <FormHelperText error sx={{ marginLeft: 0 }}>
                  {
                    errors?.applications?.[index]?.["working_with_agency"]
                      ?.message
                  }
                </FormHelperText>
              )}
            </>
          )}
        </FormControl>
        {!!errors?.applications?.[index]?.["agency_id"]?.message && (
          <FormHelperText error sx={{ mb: "0.5rem" }} id={"error-agency_id"}>
            {errors?.applications?.[index]?.["agency_id"]?.message}
          </FormHelperText>
        )}
        {!!isWorkingWithAgency && (
          <Grid
            container
            columnSpacing={3}
            sx={{
              display: isWorkingWithAgency == 1 ? "flex" : "none",
            }}
          >
            <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
              <DropdownIVF
                label="Country"
                id={`applications.${index}.agency_country_code`}
                name={`applications.${index}.agency_country_code`}
                options={lookups?.countries_list}
                disabled={disabled || isAgencyDisabled}
                value={watch(`applications.${index}.agency_country_code`)}
                {...register(`applications.${index}.agency_country_code`, {
                  onChange: (e) => {
                    getStateList(e.target.value);
                    setValue(
                      `applications.${index}.agency_country_code`,
                      watch(`applications.${index}.agency_country_code`)
                    );
                    setValue(`applications.${index}.agency_state_code`, null);
                    setValue(`applications.${index}.agency_id`, null);
                    setValue(`applications.${index}.agency_name`, null);
                    setValue(
                      `applications.${index}.agency_contact_person_name`,
                      null
                    );
                  },
                })}
                isError={
                  isWorkingWithAgency &&
                  errors?.applications?.[index]?.["agency_country_code"]
                    ?.message
                }
                errorMsg={
                  isWorkingWithAgency &&
                  errors?.applications?.[index]?.["agency_country_code"]
                    ?.message
                }
              />
            </Grid>
            <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
              <DropdownIVF
                label="State"
                id={`applications.${index}.agency_state_code`}
                name={`applications.${index}.agency_state_code`}
                options={stateList}
                disabled={
                  disabled ||
                  isAgencyDisabled ||
                  !watch(`applications.${index}.agency_country_code`)
                }
                value={watch(`applications.${index}.agency_state_code`)}
                {...register(`applications.${index}.agency_state_code`, {
                  onChange: async (e) => {
                    setLoading(true);
                    await getAgencyList(e.target.value);
                    setLoading(false);
                    setValue(
                      `applications.${index}.agency_state_code`,
                      watch(`applications.${index}.agency_state_code`)
                    );
                    setValue(`applications.${index}.agency_id`, null);
                    setValue(`applications.${index}.agency_name`, null);
                    setValue(
                      `applications.${index}.agency_contact_person_name`,
                      null
                    );
                  },
                })}
                isError={
                  isWorkingWithAgency &&
                  errors?.applications?.[index]?.["agency_state_code"]?.message
                }
                errorMsg={
                  isWorkingWithAgency &&
                  errors?.applications?.[index]?.["agency_state_code"]?.message
                }
              />
            </Grid>
            <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
              <AutoComplete
                {...register(`applications.${index}.agency_name`)}
                label="Agency Name"
                id={`applications.${index}.agency_name`}
                placeholder="Start typing agency name..."
                options={agencyList ?? []}
                optionLabel={(option) =>
                  option?.name ||
                  watch(`applications.${index}.agency_name`) ||
                  ""
                }
                setShowAddNewAgency={setWarningAddNewAgency}
                disabled={
                  disabled ||
                  isAgencyDisabled ||
                  !watch(`applications.${index}.agency_state_code`)
                }
                value={watch(`applications.${index}.agency_name`)}
                buttonText="Add New Agency"
                onInputChange={(_, value) => {
                  setValue(`applications.${index}.agency_name`, value);
                }}
                onChange={(data) => {
                  if (data?.is_active === -1) {
                    setBlackListedAgency(true);
                    setValue(`applications.${index}.agency_id`, null);
                    setValue(`applications.${index}.agency_name`, null);
                    setValue(
                      `applications.${index}.agency_contact_person_name`,
                      null
                    );
                  } else {
                    if (data?.is_active === 0) {
                      setUnverifiedOrgModal("Selecting Unverified Agency");
                    }
                    setError(`applications.${index}.agency_id`, null);
                    setError(`applications.${index}.agency_name`, null);
                    setValue(`applications.${index}.agency_id`, data?.id);
                    setValue(`applications.${index}.agency_name`, data?.name);
                    setValue(
                      `applications.${index}.agency_contact_person_name`,
                      data?.contact_person_name
                    );
                  }
                }}
                isError={
                  isWorkingWithAgency &&
                  errors?.applications?.[index]?.["agency_name"]?.message
                }
                errorMsg={
                  isWorkingWithAgency &&
                  errors?.applications?.[index]?.["agency_name"]?.message
                }
                name={undefined}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
              <TextInputIvf
                type="text"
                name={`applications.${index}.agency_contact_person_name`}
                id={`applications.${index}.agency_contact_person_name`}
                label="Contact Person Name"
                InputLabelProps={{ shrink: true }}
                sx={{ marginTop: "16px", width: "100%" }}
                disabled={disabled}
                required={false}
                register={register}
                setError={setError}
                isError={
                  isWorkingWithAgency &&
                  errors?.applications?.[index]?.["agency_contact_person_name"]
                    ?.message
                }
                errorMsg={
                  isWorkingWithAgency &&
                  errors?.applications?.[index]?.["agency_contact_person_name"]
                    ?.message
                }
              />
            </Grid>
          </Grid>
        )}
      </Accordion>
      {showAddNewAgency && (
        <AddNewAgency
          open={showAddNewAgency}
          onClose={() => setShowAddNewAgency(false)}
          setLoading={setLoading}
          newAgencyData={({
            id,
            country_code,
            state_code,
            name,
            contact_person_name,
          }) => {
            setNewAgencyAdded(!isNewAgencyAdded);
            setValue(`applications.${index}.agency_country_code`, country_code);
            setValue(`applications.${index}.agency_state_code`, state_code);
            setValue(`applications.${index}.agency_id`, id);
            setValue(`applications.${index}.agency_name`, name);
            setValue(
              `applications.${index}.agency_contact_person_name`,
              contact_person_name
            );
            setError(`applications.${index}.agency_id`, null);
            setError(`applications.${index}.agency_name`, null);
            setError(`applications.${index}.agency_contact_person_name`, null);
          }}
        />
      )}

      {/* Warning modal if user goes to add new agency in application */}
      <CommonModal
        open={warningAddNewAgency}
        type="warning"
        onCancel={() => setWarningAddNewAgency(false)}
        onConfirm={() => {
          setWarningAddNewAgency(false);
          setShowAddNewAgency(true);
        }}
        title="Please Note"
        subTitle="Adding New Agency will require approval by New Life Agency. Please continue with the rest of the application. You will be contacted by your agent or a representative at New Life Agency. If you would like to continue with your request for adding new agency, please click OKAY button to acknowledge this condition. If you would not like to continue then, please click CANCEL to close this message and adjust your selected Agency."
      />

      {/* Warning modal if BLACKlISTED agency is selected */}
      <CommonModal
        open={isBlackListedAgency}
        type="warning"
        onConfirm={() => setBlackListedAgency(false)}
        title="Please Note"
        subTitle={`We are sorry!<br />At this time, we are not accepting applications for coverage, from this agency.<br/>If you have any questions, please contact your broker.`}
      />
    </>
  );
};

export default AgencyDetailForm;
