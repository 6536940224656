/*
 * Wizard settings page
 */

import React from "react";
import { useNavigate } from "react-router-dom";
import { ThemeProvider } from "@mui/styles";
import { Button, Grid, Typography } from "@mui/material";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";

import { US_ID } from "../../constants";
import { AllRoutes } from "../../routes";
import { theme } from "../../styles/theme";
import { AdminPageLayout } from "../../layouts/admin-layout/AdminPageLayout";

const WizardSettings = () => {
  const navigate = useNavigate();

  const information = [
    {
      title: "Agencies Information",
      route: `${AllRoutes.Agencies.route}`,
    },
    {
      title: "Brokers Panel",
      route: `${AllRoutes.BrokersPanel.route}`,
    },
    {
      title: "Certificate Batch Download",
      route: `${AllRoutes.CertificateBatch.route}`,
    },
    // {
    //   title: "Countries Table",
    //   route: `${AllRoutes.CountryFee.route}`,
    // },
    {
      title: "Coverage Type / Optional Riders",
      route: `${AllRoutes.CoverageType.route}`,
    },
    {
      title: "IVF Clinics Information",
      route: `${AllRoutes.IvfClinics.route}`,
    },
    // {
    //   title: "Optional Riders",
    //   route: `${AllRoutes.OptionalRiders.route}`,
    // },
    {
      title: "Signed Affidavit Documents",
      route: `${AllRoutes.SignedAffidavitDocuments.route}`,
    },
    {
      title: "State Table",
      route: `${AllRoutes.StateTable.route}/${US_ID}`,
    },
  ];

  const RenderButton = ({ info }) => {
    const { title, route } = info;
    return (
      <Grid
        item
        key={info.title}
        xs={12}
        sm={12}
        md={6}
        lg={4}
        xl={4}
        role="listitem"
      >
        <Button
          sx={{
            width: "100%",
            height: "74px",
            borderRadius: "5px",
            color: theme.black,
            font: "normal normal normal 16px/20px Lato",
            fontWeight: "500",
            border: "1px solid #919298",
            justifyContent: "flex-start",
            boxShadow: "0 4px 10px 0 rgba(71, 62, 103, 0.1)",
          }}
          variant="outlined"
          startIcon={
            <MedicalServicesIcon
              sx={{
                color: "#C0580E",
                borderRadius: "50%",
                background: " #C0580E33",
                borderWidth: "100%",
                padding: "3px",
              }}
            />
          }
          onClick={() => navigate(route)}
        >
          {title}
        </Button>
      </Grid>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <AdminPageLayout>
        <Grid container rowSpacing={3} columnSpacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="h1" sx={{ marginBottom: "2rem" }}>
              Wizard Settings
            </Typography>
          </Grid>
          <Grid
            container
            rowSpacing={2}
            columnSpacing={2}
            sx={{ paddingLeft: "1rem" }}
            role="list"
          >
            {information?.map((info) => (
              <RenderButton info={info} />
            ))}
          </Grid>
        </Grid>
      </AdminPageLayout>
    </ThemeProvider>
  );
};

export { WizardSettings };
