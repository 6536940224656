/*
 * Admin -> Wizard -> Option Riders -> Table data
 */

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, IconButton } from "@mui/material";
import { AllRoutes } from "../../../routes";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import ReactTable from "../../../React Table";
import {
  CommonModal,
  GlobalLoader,
  NoRecords,
  RenderActionMenu,
  RenderCellInfo,
  RenderTableHeader,
} from "../../../components";

const TableData = ({
  caption,
  hasRecords,
  dataList,
  listData,
  setSearchText,
  setSortKey,
  setSortOrder,
  page,
  setPage,
  loading,
  dataLoading,
  setSearchButton,
  setSearchParams,
}) => {
  const navigate = useNavigate();
  const [clickable, setClickable] = useState([]);
  const [actionAnchorEl, setActionAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [actionLoading, setActionLoading] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);

  const handlePageChange = (p) => {
    setPage((prev) => {
      setSearchParams((searchParams) => {
        searchParams.set("page", String(p(prev)));
        return searchParams;
      });
      return p(prev);
    });
  };

  const openActionDropDown = (e, rowData) => {
    const { id } = rowData ?? {};

    let allowed_actions = [
      {
        text: "Edit",
        route: () => {}
      },
    ];

    setClickable(allowed_actions);
    setSelectedRow(rowData);
    setActionAnchorEl(e.currentTarget);
  };

  const closeActionDropDown = () => {
    setSelectedRow(null);
    setActionAnchorEl(null);
    setClickable([]);
  };

  const tableColumns = [
    {
      id: "Action",
      header: "Action",
      cell: ({ row }) => (
        <IconButton onClick={(e) => openActionDropDown(e, row.original)}>
          <MoreHorizIcon />
        </IconButton>
      ),
    },
    {
      id: "Name",
      accessorKey: "name",
      header: ({ column }) => (
        <RenderTableHeader column={column} headerName="Name" headerKey="name" />
      ),
      cell: (info) => <RenderCellInfo text={info.getValue()} />,
      footer: (props) => props.column.id,
    },
    {
      id: "Policy Key Code",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Policy Key Code"
          headerKey="policy_key_code"
        />
      ),
      accessorKey: "policy_key_code",
      cell: (info) => <RenderCellInfo text={info.getValue()} />,
      footer: (props) => props.column.id,
    },
    {
      id: "Attached Policy",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Attached Policy"
          headerKey="attached_policy"
        />
      ),
      accessorKey: "attached_policy",
      cell: (info) => <RenderCellInfo text={info.getValue()} />,
      footer: (props) => props.column.id,
    },
    {
      id: "Premium",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Premium"
          headerKey="premium"
        />
      ),
      accessorKey: "premium",
      cell: (info) => <RenderCellInfo text={info.getValue()} />,
      footer: (props) => props.column.id,
    },
  ];

  return (
    <>
      {loading ? (
        <GlobalLoader loading={loading} />
      ) : hasRecords ? (
        <Box>
          <ReactTable
            tableColumns={tableColumns}
            tableData={dataList}
            page={page}
            setPage={handlePageChange}
            listData={listData}
            onSearch={setSearchText}
            caption={caption}
            dataLoading={dataLoading}
            setDataFetching={setSearchButton}
          />
          <RenderActionMenu
            anchorEl={actionAnchorEl}
            handleClose={closeActionDropDown}
            actions={clickable}
          />
        </Box>
      ) : (
        <NoRecords />
      )}

      {actionLoading && <GlobalLoader loading={actionLoading} />}

      {/* Modal for successful update of clinic status */}
      <CommonModal
        open={updateModal}
        type="success"
        onConfirm={() => setUpdateModal(false)}
        title="Status Updated successfully!"
      />
    </>
  );
};

export { TableData };
