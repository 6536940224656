/**
 * App string constant
 * All static text should be import here
 * @format
 */

export const strings = {
  batchDownload: "Batch Download",
  editAgency: "Edit Agency",
  editBroker: "Edit Broker",
  editClinic: "Edit Clinic",
  exportExcel: "Export Excel",
  editFee: "Edit Fee",
  manageFee: "Manage Fee",
  suspended: "Suspended",
  voidedCertificates: "Voided Certificates",
  loginAsThisUser: "Login as this user",
};
