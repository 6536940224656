import { CreateNewPassword } from "../pages/auth/CreateNewPassword";
import { ForgotPassword } from "../pages/auth/ForgotPassword";
import { LoginPage } from "../pages/auth/LoginPage";
import { IVFPolicies } from "../pages/ivf/ivf-user/ivf-user-table";
import { NewApplication } from "../pages/new-application";
import { UserProfile } from "../pages/profile";
import { AddResponse } from "../pages/change-request/AddResponse";
import { Admin_ChangeRequest } from "../pages/change-request/table";
import { Sad_EditApplication } from "../pages/sad/sad-admin/sad-edit-form";
import { SAD_EditStatus } from "../pages/sad/sad-admin/EditStatus";
import { SadAdminPolicies } from "../pages/sad/sad-admin/sad-admin-table";
import { SadUserPolicies } from "../pages/sad/sad-user/sad-user-table";
import { User_CancelRequest } from "../pages/user/CancelRequest";
import { User_ChangeRequest } from "../pages/user/ChangeRequest";
import { SadForm } from "../pages/sad/sad-user/sad-form";
import { SignUpPage } from "../pages/auth/SignUpPage";
import { IvfForm } from "../pages/ivf/ivf-user/ivf-form";
import { PolicyExtension } from "../pages/sad/policy-extension";
import { GetStatement } from "../pages/get-statement";
import { CountryFee } from "../pages/wizard-settings/country-fee";
import { EditCountryFee } from "../pages/wizard-settings/country-fee/EditCountryFee";
import { SalesReport } from "../pages/sales-report";
import { WizardSettings } from "../pages/wizard-settings";
import { CoverageTypeTable } from "../pages/wizard-settings/coverage-type";
import { SignedAffidavitDocuments } from "../pages/wizard-settings/signed-affidavit-documents";
import { CertificateBatch } from "../pages/wizard-settings/certificate-batch-download";
import { IvfClinics } from "../pages/wizard-settings/ivf-clinics";
import { AddEditClinic } from "../pages/wizard-settings/ivf-clinics/AddEditClinic";
import { Agencies } from "../pages/wizard-settings/agencies";
import { AddEditAgency } from "../pages/wizard-settings/agencies/AddEditAgency";
import { UserAccounts } from "../pages/user-account/table";
import { EditUser } from "../pages/user-account/EditUser";
import { AddNewUser } from "../pages/user-account/AddNewUser";
import { IVF_EditApplication } from "../pages/ivf/ivf-admin/ivf-edit-form";
import { IVFAdminPolicies } from "../pages/ivf/ivf-admin/ivf-admin-table";
import { PaymentLandingPage } from "../pages/payment";
import { PrivacyPolicy } from "../pages/auth/PrivacyPolicy";
import { BrokersList } from "../pages/wizard-settings/brokers-panel/brokers-table";
import { AddEditBroker } from "../pages/wizard-settings/brokers-panel/AddEditBroker";
import { ViewResponse } from "../pages/change-request/ViewResponse";
import { IVFBrokerPolicies } from "../pages/broker-module/ivf/broker-ivf-table";
import { IVFBrokerAddNotes } from "../pages/broker-module/ivf/AddNotes";
import { SadBrokerPolicies } from "../pages/broker-module/sad/broker-sad-table";
import { SADBrokerAddNotes } from "../pages/broker-module/sad/AddNotes";
import { Error404Component } from "../components";
import { IVF_EditStatus } from "../pages/ivf/ivf-admin/EditStatus";
import { StateList } from "../pages/wizard-settings/country-fee/state";
import { BrokerUsers } from "../pages/wizard-settings/brokers-panel/broker-users/table";
import { StateTax } from "../pages/wizard-settings/state-tax";
import { AddEditStateTax } from "../pages/wizard-settings/state-tax/AddEditStateTax";
import { INSURANCE_TYPES, USER_ROLES } from "../constants";
import { PolicyExtensionSummary } from "../pages/sad/policy-extension/Summary";
import { Admin_PolicyExtension } from "../pages/admin-policy-extension/table";
import { Countries_Add_Edit_State } from "../pages/wizard-settings/country-fee/state/Countries_Add_Edit_State";
import { OptionalRiders } from "../pages/wizard-settings/optional-riders";
import { AgencyUsers } from "../pages/wizard-settings/agencies/agency-users/table";
import { ClinicUsers } from "../pages/wizard-settings/ivf-clinics/clinic-users/table";
import { StateTable } from "../pages/wizard-settings/state-table";
import { EditPlan } from "../pages/wizard-settings/coverage-type/EditPlan";

const { all, agency, clinic, ip, admin, broker } = USER_ROLES;

export const AllRoutes = {
  /* -------- Routes for any user -------- */
  Login: {
    route: "/",
    component: LoginPage,
    props: { title: "Login" },
    role: [all],
  },
  SignUp: {
    route: "/signup",
    component: SignUpPage,
    props: { title: "Signup" },
    role: [all],
  },
  PrivacyPolicy: {
    route: "/privacy-policy",
    component: PrivacyPolicy,
    role: [all],
  },
  ForgotPassword: {
    route: "/forgot-password",
    component: ForgotPassword,
    role: [all],
  },
  CreateNewPassword: {
    route: "/reset-password",
    component: CreateNewPassword,
    children: [{ route: ":token/:user_id", component: CreateNewPassword }],
    role: [all],
  },
  Error404Page: {
    route: "/404",
    component: Error404Component,
    role: [all],
  },

  /* -------- Routes for AGENCY/IP/CLINIC users -------- */
  Profile: {
    route: "/profile",
    component: UserProfile,
    role: [agency, ip, clinic, admin],
  },
  NewApplicationPage: {
    route: "/new-application",
    component: NewApplication,
    role: [agency, ip, clinic],
  },
  IvfCyclePage: {
    route: "/ivf-form",
    component: IvfForm,
    children: [{ route: ":id", component: IvfForm }],
    role: [agency, ip, clinic],
  },
  IvfPoliciesPage: {
    route: "/ivf-policies",
    component: IVFPolicies,
    role: [agency, ip, clinic],
  },
  IvfChangeReqPage: {
    route: "/ivf-user-change-request",
    component: () => <User_ChangeRequest type="ivf" />,
    children: [{ route: ":id", component: User_ChangeRequest }],
    role: [agency, ip, clinic],
  },
  IvfCancelReqPage: {
    route: "/ivf-user-cancel-request",
    component: () => <User_CancelRequest type="ivf" />,
    children: [{ route: ":id", component: User_CancelRequest }],
    role: [agency, ip, clinic],
  },
  SadForm: {
    route: "/sad-form",
    component: SadForm,
    children: [{ route: ":id", component: SadForm }],
    role: [agency, ip, clinic],
  },
  SadPoliciesPage: {
    route: "/sad-policies",
    component: SadUserPolicies,
    role: [agency, ip, clinic],
  },
  SadChangeReqPage: {
    route: "/sad-user-change-request",
    component: () => <User_ChangeRequest type="sad" />,
    children: [{ route: ":id", component: User_ChangeRequest }],
    role: [agency, ip, clinic],
  },
  SadCancelReqPage: {
    route: "/sad-user-cancel-request",
    component: () => <User_CancelRequest type="sad" />,
    children: [{ route: ":id", component: User_CancelRequest }],
    role: [agency, ip, clinic],
  },
  PaymentLandingPage: {
    route: "/payment",
    component: PaymentLandingPage,
    children: [{ route: ":insurance_type/:id", component: PaymentLandingPage }],
    role: [agency, ip, clinic],
  },
  PolicyExtension: {
    route: "/policy-extension",
    component: PolicyExtension,
    children: [{ route: ":id", component: PolicyExtension }],
    role: [agency, ip, clinic],
  },
  PolicyExtensionSummary: {
    route: "/policy-extension-summary",
    component: PolicyExtensionSummary,
    children: [{ route: ":id", component: PolicyExtensionSummary }],
    role: [agency, ip, clinic],
  },
  GetStatement: {
    route: "/get-statement",
    component: GetStatement,
    role: [agency, ip, clinic],
  },

  /* -------- Routes for BROKER user -------- */
  IVFBrokerPolicies: {
    route: "/ivf-broker-policies",
    component: IVFBrokerPolicies,
    role: [broker],
    insuranceType: [INSURANCE_TYPES.ivf],
  },
  IVFBrokerAddNotes: {
    route: "/ivf-broker-add-notes",
    component: IVFBrokerAddNotes,
    children: [{ route: ":id", component: IVFBrokerAddNotes }],
    role: [broker],
    insuranceType: [INSURANCE_TYPES.ivf],
  },
  Ivf_EditStatus: {
    route: "/ivf-edit-status",
    component: IVF_EditStatus,
    children: [{ route: ":id", component: IVF_EditStatus }],
    role: [broker],
    insuranceType: [INSURANCE_TYPES.ivf],
  },
  SadBrokerPolicies: {
    route: "/sad-broker-policies",
    component: SadBrokerPolicies,
    role: [broker],
    insuranceType: [INSURANCE_TYPES.sad],
  },
  SadBrokerAddNotes: {
    route: "/sad-broker-add-notes",
    component: SADBrokerAddNotes,
    children: [{ route: ":id", component: SADBrokerAddNotes }],
    role: [broker],
    insuranceType: [INSURANCE_TYPES.sad],
  },
  Sad_EditStatus: {
    route: "/sad-edit-status",
    component: SAD_EditStatus,
    children: [{ route: ":id", component: SAD_EditStatus }],
    role: [broker],
    insuranceType: [INSURANCE_TYPES.sad],
  },

  /* -------- Routes for ADMIN user -------- */
  IvfAdminPoliciesPage: {
    route: "/ivf-insurance",
    component: IVFAdminPolicies,
    role: [admin],
  },
  Ivf_EditApplication: {
    route: "/ivf-edit-form",
    component: IVF_EditApplication,
    children: [{ route: ":id", component: IVF_EditApplication }],
    role: [admin],
  },
  SadInsurancePage: {
    route: "/sad-insurance",
    component: SadAdminPolicies,
    role: [admin],
  },
  Sad_EditApplication: {
    route: "/sad-edit-form",
    component: Sad_EditApplication,
    children: [{ route: ":id", component: Sad_EditApplication }],
    role: [admin],
  },
  AddResponse: {
    route: "/add-response",
    component: AddResponse,
    children: [{ route: ":id", component: AddResponse }],
    role: [admin],
  },
  ViewResponse: {
    route: "/view-response",
    component: ViewResponse,
    children: [{ route: ":id", component: ViewResponse }],
    role: [admin],
  },
  Admin_ChangeRequest: {
    route: "/admin-change-request",
    component: Admin_ChangeRequest,
    role: [admin],
  },
  Admin_PolicyExtension: {
    route: "/admin-policy-extension",
    component: Admin_PolicyExtension,
    role: [admin],
  },
  CountryFee: {
    route: "/countries",
    component: CountryFee,
    role: [admin],
  },
  EditCountryFee: {
    route: "/countries-edit",
    component: EditCountryFee,
    children: [{ route: ":id", component: EditCountryFee }],
    role: [admin],
  },
  StateList: {
    route: "/countries/states",
    component: StateList,
    children: [{ route: ":country_id", component: StateList }],
    role: [admin],
  },
  StateTable: {
    route: "/states",
    component: StateTable,
    children: [{ route: ":country_id", component: StateTable }],
    role: [admin],
  },
  AddNewState: {
    route: "/countries/states/add",
    component: Countries_Add_Edit_State,
    children: [{ route: ":country_id", component: Countries_Add_Edit_State }],
    role: [admin],
  },
  EditState: {
    route: "/countries/states/edit",
    component: Countries_Add_Edit_State,
    children: [
      { route: ":country_id/:state_id", component: Countries_Add_Edit_State },
    ],
    role: [admin],
  },
  StateTax: {
    route: "/state-tax",
    component: StateTax,
    children: [{ route: ":country_code/:state_code", component: StateTax }],
    role: [admin],
  },
  AddNewStateTax: {
    route: "/add-state-tax",
    component: AddEditStateTax,
    children: [
      { route: ":country_code/:state_code", component: AddEditStateTax },
    ],
    role: [admin],
  },
  EditStateTax: {
    route: "/edit-state-tax",
    component: AddEditStateTax,
    children: [
      {
        route: ":fee_id/:country_code/:state_code",
        component: AddEditStateTax,
      },
    ],
    role: [admin],
  },
  SalesReport: {
    route: "/sales-report",
    component: SalesReport,
    role: [admin, broker],
    insuranceType: [INSURANCE_TYPES.sad, INSURANCE_TYPES.ivf],
  },
  WizardSettings: {
    route: "/wizard-settings",
    component: WizardSettings,
    role: [admin],
  },
  CoverageType: {
    route: "/plans",
    component: CoverageTypeTable,
    role: [admin],
  },
  EditPlan: {
    route: "/plan",
    component: EditPlan,
    children: [{ route: ":plan_id", component: EditPlan }],
    role: [admin],
  },
  OptionalRiders: {
    route: "/optional-riders",
    component: OptionalRiders,
    role: [admin],
  },
  SignedAffidavitDocuments: {
    route: "/signed-affidavit-documents",
    component: SignedAffidavitDocuments,
    role: [admin],
  },
  IvfClinics: {
    route: "/ivf-clinics",
    component: IvfClinics,
    role: [admin],
  },
  AddClinic: {
    route: "/add-clinic",
    component: AddEditClinic,
    role: [admin],
  },
  EditClinic: {
    route: "/edit-clinic",
    component: AddEditClinic,
    children: [{ route: ":id", component: AddEditClinic }],
    role: [admin],
  },
  ClinicUsers: {
    route: "/clinic-users",
    component: ClinicUsers,
    children: [{ route: ":clinic_id", component: ClinicUsers }],
    role: [admin],
  },
  AddNewClinicUser: {
    route: "/add-clinic-user",
    component: AddNewUser,
    children: [{ route: ":clinic_id", component: AddNewUser }],
    role: [admin],
  },
  EditClinicUser: {
    route: "/edit-clinic-user",
    component: EditUser,
    children: [{ route: ":clinic_id/:user_id", component: EditUser }],
    role: [admin],
  },
  CertificateBatch: {
    route: "/certificate-batch-download",
    component: CertificateBatch,
    role: [admin],
  },
  Agencies: {
    route: "/agencies",
    component: Agencies,
    role: [admin],
  },
  AddAgency: {
    route: "/add-agency",
    component: AddEditAgency,
    role: [admin],
  },
  EditAgency: {
    route: "/edit-agency",
    component: AddEditAgency,
    children: [{ route: ":id", component: AddEditAgency }],
    role: [admin],
  },
  AgencyUsers: {
    route: "/agency-users",
    component: AgencyUsers,
    children: [{ route: ":agency_id", component: AgencyUsers }],
    role: [admin],
  },
  AddNewAgencyUser: {
    route: "/add-agency-user",
    component: AddNewUser,
    children: [{ route: ":agency_id", component: AddNewUser }],
    role: [admin],
  },
  EditAgencyUser: {
    route: "/edit-agency-user",
    component: EditUser,
    children: [{ route: ":agency_id/:user_id", component: EditUser }],
    role: [admin],
  },
  BrokersPanel: {
    route: "/brokers",
    component: BrokersList,
    role: [admin],
  },
  AddBroker: {
    route: "/add-broker",
    component: AddEditBroker,
    role: [admin],
  },
  EditBroker: {
    route: "/edit-broker",
    component: AddEditBroker,
    children: [{ route: ":id", component: AddEditBroker }],
    role: [admin],
  },
  BrokerUsers: {
    route: "/broker-users",
    component: BrokerUsers,
    children: [
      { route: ":broker_code_group/:broker_id", component: BrokerUsers },
    ],
    role: [admin],
  },
  AddNewBrokerUser: {
    route: "/add-broker-user",
    component: AddNewUser,
    children: [{ route: ":broker_code_group", component: AddNewUser }],
    role: [admin],
  },
  EditBrokerUser: {
    route: "/edit-broker-user",
    component: EditUser,
    children: [{ route: ":broker_code_group/:user_id", component: EditUser }],
    role: [admin],
  },
  UserAccounts: {
    route: "/user-accounts",
    component: UserAccounts,
    role: [admin],
  },
  EditUser: {
    route: "/edit-user",
    component: EditUser,
    children: [{ route: ":user_id", component: EditUser }],
    role: [admin],
  },
  AddNewUser: {
    route: "/add-user",
    component: AddNewUser,
    role: [admin],
  },

  // UPDATE_PROFILE: {
  //     route: '/dashboard/update-profile',
  //     component: UpdateProfilePage
  // },
  // UPDATE_PASSWORD: {
  //     route: '/dashboard/update-password',
  //     component: UpdatePasswordPage
  // },
  // WEBPAGES: {
  //     route: '/dashboard/pages',
  //     props: {
  //         entitySingular: 'Page',
  //         entityPlural: 'Pages',
  //         title: 'Manage Pages',
  //         postType: PostTypes.PAGES,
  //     },
  //     component: WebPostsPage,
  //     children: [
  //         { route: ":id", component: WebPostsPage }
  //     ]
  // },
};

export const getUserRoutes = (userData) => {
  const { role: userRole, broker: brokerData } = userData ?? {};
  const { insurance_type } = brokerData ?? {};

  const filteredRoutes = {};
  Object.keys(AllRoutes).forEach((k) => {
    if (
      (!brokerData || !insurance_type) &&
      (AllRoutes[k].role.includes(userRole) || AllRoutes[k].role.includes(all))
    ) {
      filteredRoutes[k] = AllRoutes[k];
    } else if (
      AllRoutes[k]?.insuranceType?.includes?.(insurance_type) &&
      (AllRoutes[k].role.includes(userRole) || AllRoutes[k].role.includes(all))
    ) {
      filteredRoutes[k] = AllRoutes[k];
    }
  });
  return filteredRoutes;
};
