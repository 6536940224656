/*
 * IVF admin application header
 */

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Grid, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch } from "react-redux";

import { Tooltip } from "../../../../components";
import { PolicyStatusValue } from "../../../sad/constants";
import { setIvfSummary } from "../../../../redux/slices/globalSlice";
import { coverageTypeLabel, INSURANCE_TYPES } from "../../../../constants";
import { usePlansWithoutRiders, usePlansWithRiders } from "../../../../hooks";

const IvfApplicationHeader = ({ header, setLoading }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const summaryData = useSelector((state) => state.ivfReducer.ivfSummary);
  const lookups = useSelector((state) => state.commonReducer.lookups);
  const { application_status_types_list } = lookups ?? {};
  const { getIndemnityLabelByValue } = usePlansWithoutRiders(
    INSURANCE_TYPES.ivf
  );
  const { getRiderLabelByValue } = usePlansWithRiders(INSURANCE_TYPES.ivf);
  const {
    status,
    application_number,
    insurance_type,
    coverage_type,
    term_length,
    idemnity_limit,
    reproductive_organ_loss_rider,
    reproductive_organ_loss_rider_option,
  } = summaryData?.[0] ?? {};

  useEffect(() => {
    !!setLoading && !!Object.entries(summaryData)?.length && setLoading(false);
  }, [summaryData]);

  const getValue = (value) => {
    return application_number ? value : "";
  };

  const generalInformation = [
    {
      value: getValue(PolicyStatusValue(application_status_types_list, status)),
      label: "Policy Status:",
    },
    {
      value: getValue(application_number),
      label: "Policy Number:",
    },
    {
      value: getValue(coverageTypeLabel(insurance_type, coverage_type)),
      label: "Coverage Type:",
    },
  ];

  const submitInformation = [
    {
      value: getValue(`${term_length} Months`),
      label: "Term Length:",
    },
    {
      value: getValue(
        getIndemnityLabelByValue(coverage_type, term_length, idemnity_limit)
      ),
      label: "Limit of Indemnity:",
    },
    {
      value: getValue(
        reproductive_organ_loss_rider
          ? `Yes ${getRiderLabelByValue(
              term_length,
              reproductive_organ_loss_rider_option
            )}`
          : "No"
      ),
      label: "Reproductive Organ Loss Rider:",
    },
  ];

  const RenderSummaryItem = ({ options = [] }) => {
    return (
      <Grid container>
        {!!options &&
          options?.map((option) => (
            <React.Fragment key={option.label}>
              <Grid item xl={5} lg={5} md={5} sm={5} xs={5}>
                <Typography
                  sx={{
                    fontSize: "0.9rem",
                    color: "#222222",
                    lineHeight: "2rem",
                  }}
                >
                  {option.label}
                </Typography>
              </Grid>
              <Grid item xl={7} lg={7} md={7} sm={7} xs={7}>
                <Typography
                  sx={{
                    fontSize: "0.9rem",
                    fontWeight: "bold",
                    lineHeight: "2rem",
                  }}
                >
                  {option.value}
                </Typography>
              </Grid>
            </React.Fragment>
          ))}
      </Grid>
    );
  };

  return (
    <>
      <Typography variant="h1">
        <Tooltip title="Back to Previous Page">
          <ArrowBackIcon
            onClick={() => {
              dispatch(setIvfSummary([]));
              navigate(-1);
            }}
            sx={{ marginRight: "1rem", cursor: "pointer" }}
          />
        </Tooltip>
        {header}
      </Typography>

      <Grid
        container
        sx={{
          backgroundColor: "#F082331A",
          padding: "10px",
          marginTop: "0.7rem",
        }}
      >
        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
          <RenderSummaryItem options={generalInformation} />
        </Grid>
        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
          <RenderSummaryItem options={submitInformation} />
        </Grid>
      </Grid>
    </>
  );
};

export { IvfApplicationHeader };
