/*
 * Admin -> Wizard -> Coverage Type Table
 */

import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";

import { PlansApis } from "../../../api";
import { TableData } from "./TableData";
import { TableHeader } from "./TableHeader";
import { AllRoutes } from "../../../routes";
import { GoBack } from "../../../components";
import { INSURANCE_TYPES } from "../../../constants";
import { AdminPageLayout } from "../../../layouts/admin-layout/AdminPageLayout";

const CoverageTypeTable = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dataLoading, setDataLoading] = useState(true);
  const [selectedInsurance, setSelectedInsurance] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);

  const propertyMapping = {
    insurance_type: setSelectedInsurance,
    riders_only: setSelectedPlan,
  };

  useEffect(() => {
    setSearchParams((searchParams) => {
      if (!searchParams.has("insurance_type")) {
        searchParams.set("insurance_type", INSURANCE_TYPES.ivf);
      }
      if (!searchParams.has("riders_only")) {
        searchParams.set("riders_only", 0);
      }
      return searchParams;
    });

    const params = [];
    searchParams.forEach((value, key) => {
      params.push([key, value]);
    });
    const obj = params.reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});

    Object.keys(propertyMapping)?.forEach((key) => {
      if (obj?.hasOwnProperty(key)) {
        propertyMapping?.[key]?.(obj?.[key]);
      }
    });
  }, []);

  useEffect(() => {
    if (selectedInsurance && selectedPlan !== null) {
      getCoveragePlans();
    }
  }, [selectedInsurance, selectedPlan]);

  const getCoveragePlans = async () => {
    setDataLoading(true);
    let params = {
      insurance_type: selectedInsurance,
      riders_only: selectedPlan,
    };
    const resp = await PlansApis.getPlans(params);
    if (resp) {
      setDataLoading(false);
      setLoading(false);
    }
    setDataList(resp?.data ?? []);
  };

  return (
    <AdminPageLayout>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <GoBack navigateURL={AllRoutes.WizardSettings.route} />
        <Typography variant="h1" sx={{ margin: "0" }}>
          Coverage Type / Optional Riders
        </Typography>
      </Box>

      <TableHeader
        selectedInsurance={selectedInsurance}
        setSelectedInsurance={setSelectedInsurance}
        selectedPlan={selectedPlan}
        setSelectedPlan={setSelectedPlan}
        setSearchParams={setSearchParams}
      />

      <TableData
        caption="Coverage Type / Optional Riders"
        dataList={dataList}
        selectedInsurance={selectedInsurance}
        selectedPlan={selectedPlan}
        loading={loading}
        dataLoading={dataLoading}
      />
    </AdminPageLayout>
  );
};

export { CoverageTypeTable };
