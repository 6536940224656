/**
 * Settings APIs
 */

import httpClient from "../utils/HttpUtils";

export const SettingsApis = {
  getZohoStatus: async () => {
    try {
      const resp = await httpClient.get("settings/ZOHO_SYNC_REFRESH_TOKEN");
      return resp;
    } catch (error) {
      console.log("Error getZohoStatus", error);
    }
  },
};
