/*
 * Applications Utils
 */

import { ALLOWED_ACTIONS } from "../constants";

export const ApplicationUtils = {
  nonEditableApplication: (application = {}) => {
    return !application?.allowed_actions?.some(
      (action) => action.value === ALLOWED_ACTIONS.EDIT_APPLICATION
    );
  },
};
