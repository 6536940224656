/*
 * BROKER IVF- TABLE DATA
 */

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  IconButton,
  Button,
  Typography,
  CircularProgress,
  Grid,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

import {
  CommonModal,
  GlobalLoader,
  NoRecords,
  RenderActionMenu,
  RenderCellInfo,
  RenderChip,
  RenderDocumentIcon,
  RenderTableHeader,
  Tooltip,
} from "../../../../components";
import { formatString, formattedMDYDate } from "../../../../utils/CommonUtils";
import { AllRoutes } from "../../../../routes";
import { AttachmentApis } from "../../../../api";
import ReactTable from "../../../../React Table";
import { coverageTypeLabel, strings } from "../../../../constants";
import { setIvfSummary } from "../../../../redux/slices/globalSlice";
import { PolicyTypes } from "../../../sad/constants";

const { canceled, underReview } = PolicyTypes;

const IVFTableComponent = ({
  caption,
  dataList,
  listData,
  setSearchText,
  setSortKey,
  setSortOrder,
  page,
  setPage,
  loading,
  dataLoading,
  setSearchParams,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [clickable, setClickable] = useState([]);
  const [actionAnchorEl, setActionAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showDocumentModal, setShowDocumentModal] = useState(false);
  const [documentLink, setDocumentLink] = useState("");
  const [hiddenTitle, setHiddenTitle] = useState("");

  const handlePageChange = (p) => {
    setPage((prev) => {
      setSearchParams((searchParams) => {
        searchParams.set("page", String(p(prev)));
        return searchParams;
      });
      return p(prev);
    });
  };

  const openActionDropDown = (e, rowData) => {
    {
      setClickable([
        {
          text: "Add Comment",
          route: () => {
            dispatch(setIvfSummary([]));
            navigate(`${AllRoutes.IVFBrokerAddNotes.route}/${rowData?.id}`);
          },
        },
      ]);
    }
    setSelectedRow(rowData);
    setActionAnchorEl(e.currentTarget);
  };

  const closeActionDropDown = () => {
    setSelectedRow(null);
    setActionAnchorEl(null);
    setClickable([]);
  };

  const getPolicyDocs = async (application_id, policy_doc_id) => {
    setShowDocumentModal(true);
    setHiddenTitle(formatString(policy_doc_id));

    const payload = { application_id, policy_doc_id };
    const resp = await AttachmentApis.getPolicyDoc(payload);
    !!resp ? setDocumentLink(resp) : setShowDocumentModal(false);
  };

  const tableColumns = [
    {
      id: "Action",
      header: "Action",
      cell: ({ row }) => {
        const { is_suspended, status } = row?.original ?? {};
        return (
          <IconButton
            disabled={status === canceled && !is_suspended}
            onClick={(e) => openActionDropDown(e, row.original)}
            aria-label={"More actions of " + row.original?.application_number}
          >
            <MoreHorizIcon />
          </IconButton>
        );
      },
    },
    {
      id: "Policy Status",
      accessorKey: "status",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Policy Status"
          headerKey="status"
        />
      ),
      cell: (info) => {
        const status = info.getValue();
        const { is_suspended } = info?.row?.original ?? {};
        return (
          <RenderChip
            label={
              status === underReview
                ? "Submitted"
                : status === canceled && is_suspended
                ? strings.suspended
                : undefined
            }
            status={status}
          />
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Policy Number",
      accessorKey: "application_number",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Policy Number"
          headerKey="application_number"
        />
      ),
      cell: (info) => (
        <RenderCellInfo text={info.getValue()} sx={{ color: "#0A7691" }} />
      ),
      footer: (props) => props.column.id,
    },
    {
      id: "Coverage Type",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Coverage Type"
          headerKey="coverage_type"
        />
      ),
      accessorKey: "coverage_type",
      cell: (info) => {
        const { coverage_type, insurance_type } = info.row?.original;
        return (
          <RenderCellInfo
            text={coverageTypeLabel(insurance_type, coverage_type)}
          />
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Alert Status",
      header: "Alert Status",
      accessorKey: "alerts",
      cell: (info) => {
        const { cleared_at } = info?.row?.original ?? {};
        const alertStatus = info.getValue()?.length
          ? info
              .getValue()
              ?.map((alert, index) => (
                <div key={index}> {`${alert ?? ""}`}</div>
              ))
          : "";
        return info.getValue()?.length === 0 ? (
          <div className="text-center">
            <Tooltip title={"No Alerts"}>
              <FiberManualRecordIcon
                fontSize="small"
                className="none-text-grey"
                title={undefined}
              />
            </Tooltip>
            <span className="hidden-visually">No Alerts</span>
          </div>
        ) : (
          <div className="text-center">
            <Tooltip tooltip={alertStatus}>
              {!!cleared_at ? (
                <CheckCircleIcon
                  fontSize="small"
                  className="none-text-green"
                  title={undefined}
                />
              ) : (
                <FiberManualRecordIcon
                  fontSize="small"
                  className="none-text-red"
                  title={undefined}
                />
              )}
            </Tooltip>
            <div className="hidden-visually">{alertStatus}</div>
          </div>
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Payment Status",
      header: "Payment Status",
      accessorKey: "payment_status",
      cell: (info) => {
        const { invoice } = info.row.original ?? {};
        const paymentStatus = invoice?.payment_status;
        let icon;

        if (paymentStatus === "paid") {
          icon = (
            <>
              <Tooltip title={"Paid"}>
                <CheckCircleIcon
                  fontSize="small"
                  className="none-text-green"
                  title={undefined}
                />
              </Tooltip>
              <span className="hidden-visually">Paid</span>
            </>
          );
        } else if (paymentStatus === "pending") {
          icon = (
            <>
              <Tooltip title={"Pending"}>
                <WatchLaterIcon
                  fontSize="small"
                  className="none-text-orange"
                  title={undefined}
                />
              </Tooltip>
              <span className="hidden-visually">Pending</span>
            </>
          );
        } else {
          icon = (
            <>
              <Tooltip title={"Unpaid"}>
                <CancelIcon
                  fontSize="small"
                  className="none-text-red"
                  title={undefined}
                />
              </Tooltip>
              <span className="hidden-visually">Pending</span>
            </>
          );
        }
        return (
          <div style={{ textAlign: "center", margin: "auto" }}>{icon}</div>
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Documents",
      header: "Documents",
      accessorKey: "Documents",
      cell: (info) => {
        const { allowed_policy_docs } = info.row.original ?? {};
        return (
          <Box sx={{ whiteSpace: "nowrap" }}>
            <Grid container spacing={4} alignItems="center">
              <Grid item>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  {allowed_policy_docs?.map?.((d) => (
                    <RenderDocumentIcon
                      key={d.label}
                      tooltip={d?.label}
                      policy_doc_id={d?.document_id}
                      info={info}
                      getPolicyDocs={getPolicyDocs}
                    />
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Box>
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "State",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="State"
          headerKey="contractually_liable_party_state"
        />
      ),
      accessorKey: "contractually_liable_party_state",
      cell: (info) => (
        <RenderCellInfo text={info.getValue() ? info.getValue() : "-"} />
      ),
      footer: (props) => props.column.id,
    },
    {
      id: "Applicant Name & Email",
      accessorKey: "contractually_liable_party_first_name",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Applicant Name & Email"
          headerKey="contractually_liable_party_first_name"
        />
      ),
      cell: (info) => {
        const {
          created_by_first_name,
          created_by_last_name,
          created_by_email,
        } = info?.row?.original ?? {};
        return (
          <Box sx={{ textAlign: "center" }}>
            <Typography>
              {created_by_first_name || ""} {created_by_last_name || ""}
            </Typography>
            <Typography sx={{ textAlign: "center" }}>
              {created_by_email || ""}
            </Typography>
          </Box>
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Donor Name/ID",
      accessorKey: "donor_name_id",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Donor Name/ID"
          headerKey="donor_name_id"
        />
      ),
      cell: (info) => {
        return <div className="text-center">{info.getValue()}</div>;
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Recipient Name/ID",
      accessorKey: "recipient_name_id",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Recipient Name/ID"
          headerKey="recipient_name_id"
        />
      ),
      cell: (info) => {
        return <div className="text-center">{info.getValue()}</div>;
      },
      footer: (props) => props.column.id,
    },
    {
      id: "MSD of Donor",
      accessorKey: "donor_medication_start_date",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="MSD of Donor"
          headerKey="donor_medication_start_date"
        />
      ),
      cell: (info) => (
        <RenderCellInfo
          text={info.getValue() ? formattedMDYDate(info.getValue()) : ""}
        />
      ),
      footer: (props) => props.column.id,
    },
    {
      id: "MSD of Recipient",
      accessorKey: "recipient_medication_start_date",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="MSD of Recipient"
          headerKey="recipient_medication_start_date"
        />
      ),
      cell: (info) => (
        <RenderCellInfo
          text={info.getValue() ? formattedMDYDate(info.getValue()) : ""}
        />
      ),
      footer: (props) => props.column.id,
    },
    {
      id: "Payment Date",
      accessorKey: "payment_date",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Payment Date"
          headerKey="payment_date"
        />
      ),
      cell: (info) => {
        const { invoice } = info?.row?.original;
        const paymentDate = invoice?.payment_date;
        return (
          <RenderCellInfo
            text={paymentDate ? formattedMDYDate(paymentDate) : ""}
          />
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Application Date",
      accessorKey: "submitted_on",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Application Date"
          headerKey="submitted_on"
        />
      ),
      cell: (info) => (
        <RenderCellInfo
          text={info.getValue() ? formattedMDYDate(info.getValue()) : ""}
        />
      ),
      footer: (props) => props.column.id,
    },
    {
      id: "Agency",
      accessorKey: "agency_name",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Agency"
          headerKey="agency_name"
        />
      ),
      cell: (info) => <RenderCellInfo text={info.getValue()} />,
      footer: (props) => props.column.id,
    },
    {
      id: "Effective Date",
      accessorKey: "effective_from",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Effective Date"
          headerKey="effective_from"
        />
      ),
      cell: (info) => (
        <RenderCellInfo
          text={info.getValue() ? formattedMDYDate(info.getValue()) : ""}
        />
      ),
      footer: (props) => props.column.id,
    },
    {
      id: "Expiration Date",
      accessorKey: "effective_till",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Expiration Date"
          headerKey="effective_till"
        />
      ),
      cell: (info) => (
        <RenderCellInfo
          text={info.getValue() ? formattedMDYDate(info.getValue()) : ""}
        />
      ),
      footer: (props) => props.column.id,
    },
    {
      id: "Supporting Documents",
      header: "Supporting Documents",
      cell: ({ row }) => {
        const {
          id: application_id,
          coverage_type,
          donor_has_cycled_before_complications,
          recipient_has_cycled_before_complications,
        } = row.original;
        const [documents, setDocuments] = useState([]);
        const [loading, setLoading] = useState(false);
        const [documentsFetched, setDocumentsFetched] = useState(false);

        const handleFetchDocuments = async () => {
          setLoading(true);
          try {
            const response = await AttachmentApis.getAttachments({
              application_id,
            });
            if (response) {
              setDocuments(response.data);
              setDocumentsFetched(true);
            }
          } catch (error) {
            console.error("Error fetching documents:", error);
          } finally {
            setLoading(false);
          }
        };

        return (
          <>
            {loading ? (
              <CircularProgress size={24} />
            ) : (
              <>
                {documents.length > 0 ? (
                  documents.map((doc) => (
                    <div key={doc.id}>
                      <a
                        href={doc.url}
                        download={doc.original_name}
                        target="_blank"
                      >
                        {doc.original_name}
                      </a>
                    </div>
                  ))
                ) : documentsFetched ? (
                  <span>No document uploaded</span>
                ) : (
                  <Button
                    variant="outlined"
                    sx={{ padding: "3px 7px" }}
                    disabled={
                      (coverage_type == "EDO" &&
                        !donor_has_cycled_before_complications) ||
                      (coverage_type == "RO" &&
                        !recipient_has_cycled_before_complications) ||
                      (coverage_type == "EDR" &&
                        !donor_has_cycled_before_complications &&
                        !recipient_has_cycled_before_complications) ||
                      loading
                    }
                    onClick={handleFetchDocuments}
                  >
                    Fetch Documents
                    <span className="hidden-visually">
                      {" "}
                      for {row.original?.application_number}
                    </span>
                  </Button>
                )}
              </>
            )}
          </>
        );
      },
    },
  ];

  if (loading) return <GlobalLoader loading={loading} />;

  return (
    <>
      {dataList?.length > 0 ? (
        <Box>
          <ReactTable
            tableColumns={tableColumns}
            tableData={dataList}
            page={page}
            setPage={handlePageChange}
            listData={listData}
            onSearch={setSearchText}
            caption={caption}
            dataLoading={dataLoading}
          />
          <RenderActionMenu
            anchorEl={actionAnchorEl}
            handleClose={closeActionDropDown}
            actions={clickable}
          />
        </Box>
      ) : (
        <NoRecords />
      )}

      <CommonModal
        open={showDocumentModal}
        onCancel={() => {
          setShowDocumentModal(false);
          setDocumentLink("");
          setHiddenTitle("");
        }}
        cancelButtonLabel="Close"
        maxWidth="md"
        visuallyHiddenTitle={hiddenTitle}
        fullWidth
      >
        {!documentLink ? (
          <Box
            sx={{
              width: "100%",
              height: "500px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <iframe
            src={documentLink}
            width="100%"
            height="500"
            style={{ display: !documentLink ? "none" : "block" }}
          />
        )}
      </CommonModal>
    </>
  );
};

export { IVFTableComponent };
