/*
 * Get Statement Table header page
 */

import React, { useEffect } from "react";
import { Box, Grid } from "@mui/material";

import {
  convertDateRangeToString,
  oneMonthDateRangeString,
  oneMonthDateRangeText,
} from "../../utils/CommonUtils";
import { theme } from "../../styles/theme";
import { PaymentTypes } from "../sad/constants";
import { INSURANCE_TYPES, INSURANCE_TYPES_LIST } from "../../constants";
import { DateRangePicker, Dropdown, GridActionButtons } from "../../components";

const TableHeader = ({
  advancedSearch,
  setAdvancedSearch,
  insuranceType,
  setInsuranceType,
  dateRange,
  setDateRange,
  paymentStatus,
  setPaymentStatus,
  isOrgAdmin,
  orgUsers,
  submittedBy,
  setSubmittedBy,
  myData,
  setPage,
  searchButton,
  setSearchButton,
  buttonLoading,
  setButtonLoading,
  setSearchParams,
}) => {
  const setDefaultDate = () => {
    const curDate = new Date();
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
    setDateRange([oneMonthAgo, curDate]);
  };

  useEffect(() => {
    setDefaultDate();
  }, []);

  const searchValues = () => {
    setButtonLoading("search");
    setPage(1);
    setSearchButton(!searchButton);
    setSearchParams((searchParams) => {
      searchParams.set("page", 1);
      Object.entries(advancedSearch).forEach(([key, value]) => {
        searchParams.set(key, value);
      });
      return searchParams;
    });

    setAdvancedSearch(advancedSearch);
  };

  const setSearchObject = (key, value) => {
    setAdvancedSearch((p) => ({ ...p, [key]: value }));
  };

  const handleDateChange = (newDateRange) => {
    setDateRange(newDateRange);
    setAdvancedSearch((p) => ({
      ...p,
      ["created_at"]: convertDateRangeToString(newDateRange),
    }));
  };

  const onClear = () => {
    setButtonLoading("clear");
    setSearchButton(!searchButton);
    setSearchParams({
      ["created_at"]: oneMonthDateRangeText(),
      ["page"]: 1,
      ["insurance_type"]: INSURANCE_TYPES.sad,
      ...(isOrgAdmin && { ["created_by"]: myData?.id }),
    });
    setPage(1);
    setInsuranceType(INSURANCE_TYPES.sad);
    setDefaultDate();
    setPaymentStatus("");
    isOrgAdmin && setSubmittedBy(myData?.id);
    setAdvancedSearch({
      ...oneMonthDateRangeString(),
      ["insurance_type"]: INSURANCE_TYPES.sad,
      ...(isOrgAdmin && { ["created_by"]: myData?.id }),
    });
  };

  return (
    <form>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Grid container rowSpacing={2} columnSpacing={3}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Dropdown
              size="small"
              label="Insurance Type"
              options={INSURANCE_TYPES_LIST}
              value={insuranceType}
              onChange={(e) => {
                setInsuranceType(e.target.value);
                setSearchObject("insurance_type", e.target.value);
              }}
              margin="none"
              sx={{ backgroundColor: theme.white }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <DateRangePicker
              dateRange={dateRange}
              handleDateChange={handleDateChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Dropdown
              size="small"
              label="Payment Status"
              id="payment_status"
              options={[
                { label: "All", value: "" },
                { label: "Paid", value: PaymentTypes.paid },
                { label: "Pending", value: PaymentTypes.notAttempted },
              ]}
              value={paymentStatus}
              onChange={(e) => {
                setPaymentStatus(e.target.value);
                setSearchObject("payment_status", e.target.value);
              }}
              margin="none"
              sx={{ backgroundColor: "white" }}
            />
          </Grid>

          {isOrgAdmin && (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Dropdown
                size="small"
                label="Submitted By"
                id="created_by"
                options={orgUsers}
                value={submittedBy}
                onChange={(e) => {
                  setSubmittedBy(e.target.value);
                  setSearchObject("created_by", e.target.value);
                }}
                margin="none"
                sx={{ backgroundColor: "white" }}
              />
            </Grid>
          )}
        </Grid>

        <GridActionButtons
          onSearch={searchValues}
          onClear={onClear}
          searchButtonLoading={buttonLoading == "search"}
          clearButtonLoading={buttonLoading == "clear"}
          wrapperStyle={{ marginTop: "1rem" }}
        />
      </Box>
    </form>
  );
};

export { TableHeader };
