/*
 * Common Document Icon component for all grids
 */

import React from "react";
import { IconButton } from "@mui/material";
import BadgeIcon from "@mui/icons-material/Badge";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import DescriptionIcon from "@mui/icons-material/Description";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

import { Tooltip } from "../Tooltip";
import { formatString } from "../../utils/CommonUtils";
import { PolicyDocTypes } from "../../pages/sad/constants";

const { application, certificate, invoice } = PolicyDocTypes;

const RenderDocumentIcon = ({
  info,
  getPolicyDocs,
  policy_doc_id,
  tooltip,
}) => {
  const {
    id: application_id,
    docs_being_generated,
    application_number,
  } = info.row.original;
  const makeButtonLabel =
    formatString(policy_doc_id) + " for " + application_number;

  const GetIcon =
    policy_doc_id === application
      ? DescriptionIcon
      : policy_doc_id === invoice
      ? RequestPageIcon
      : policy_doc_id === certificate
      ? PictureAsPdfIcon
      : BadgeIcon;

  return (
    <Tooltip
      title={docs_being_generated ? "Docs are being generated" : tooltip}
    >
      <IconButton
        onClick={() =>
          !docs_being_generated &&
          getPolicyDocs(application_id, policy_doc_id, true)
        }
        sx={{ cursor: docs_being_generated ? "default" : "pointer" }}
        aria-label={makeButtonLabel}
      >
        <GetIcon fontSize="small" sx={{ color: "#0A7691" }} />
      </IconButton>
    </Tooltip>
  );
};

export { RenderDocumentIcon };
