/**
 * Zoho APIs
 */

import httpClient from "../utils/HttpUtils";

export const ZohoApis = {
  connect: async () => {
    try {
      const resp = await httpClient.get("zoho_connect");
      !!resp?.success && (window.location = resp?.data);
      return resp;
    } catch (error) {
      console.log("Error zoho connect", error);
    }
  },

  disconnect: async () => {
    try {
      const resp = await httpClient.delete("zoho_connect/false");
      return resp;
    } catch (error) {
      console.log("Error zoho disconnect", error);
    }
  },
};
