/*
 * Custom hook to use unique term lengths and indemnities
 * based on insurance type and coverage type (only for IVF)
 */

import { useState, useEffect, useCallback, useRef } from "react";

import { PlansApis } from "../api";
import { INSURANCE_TYPES } from "../constants";
import { formatCurrencyHuman } from "../utils/CommonUtils";

export const usePlansWithoutRiders = (insuranceType, coverageType) => {
  const [data, setData] = useState(null);
  const [uniqueTermLengths, setUniqueTermLengths] = useState([]);
  const dataRef = useRef(null);

  useEffect(() => {
    const fetchPlansData = async () => {
      if (dataRef?.current && dataRef?.current?.[insuranceType]) {
        setData(dataRef?.current?.[insuranceType]);
      } else {
        const params = { insurance_type: insuranceType, riders_only: 0 };
        const resp = await PlansApis.getPlans(params);
        const fetchedData = resp?.data ?? [];
        dataRef.current = {
          ...dataRef?.current,
          [insuranceType]: fetchedData,
        };
        setData(fetchedData);
      }
    };

    fetchPlansData();
  }, [insuranceType]);

  useEffect(() => {
    if (data) {
      const filteredData =
        insuranceType === INSURANCE_TYPES.sad && !coverageType
          ? data
          : data?.filter?.((r) => r?.coverage_type === coverageType);

      const uniqueTermLengths = [
        ...new Set(filteredData?.map?.((r) => r?.term_length)),
      ]
        ?.sort?.((a, b) => a - b)
        ?.map?.((tl) => ({ label: `${tl} months`, value: tl }));

      setUniqueTermLengths(uniqueTermLengths);
    }
  }, [data, coverageType, insuranceType]);

  const getIndemnityLimits = useCallback(
    (coverageType, termLength) => {
      if (!data || !coverageType || !termLength) {
        return [];
      }

      const filteredDataByCoverage = data?.filter?.(
        (record) => record?.coverage_type === coverageType
      );
      const filteredData = filteredDataByCoverage?.filter?.(
        (record) => record?.term_length === termLength
      );

      const formattedArray = filteredData
        ?.map?.((x) => ({
          label: `Limit ${formatCurrencyHuman(
            x?.ivf_benefit
          )} - Premium ${formatCurrencyHuman(x?.premium)}`,
          value: x?.key_code,
          description: `Product includes ${formatCurrencyHuman(
            x?.ivf_accidental_death_benefit
          )} Accidental Death and ${formatCurrencyHuman(
            x?.ivf_accidental_paralysis_benefit
          )} Accidental Paralysis.`,
        }))
        ?.sort?.((a, b) => a?.label?.localeCompare?.(b?.label));

      return formattedArray;
    },
    [data]
  );

  const getIndemnityLabelByValue = useCallback(
    (coverageType, termLength, value) => {
      const indemnityLimits = getIndemnityLimits(coverageType, termLength);
      const selectedOption = indemnityLimits?.find?.(
        (item) => item.value === value
      );
      return selectedOption?.label || "";
    },
    [getIndemnityLimits]
  );

  return { uniqueTermLengths, getIndemnityLimits, getIndemnityLabelByValue };
};
