/*
 * Admin -> Wizard -> Agencies -> Agency's users list
 */

import React, { useEffect, useState } from "react";
import { Typography, Grid, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import AddIcon from "@mui/icons-material/Add";

import { TableData } from "./TableData";
import { AllRoutes } from "../../../../../routes";
import { USER_ROLES } from "../../../../../constants";
import { Button, GoBack } from "../../../../../components";
import { AgencyApis, UsersApis } from "../../../../../api";
import { AdminPageLayout } from "../../../../../layouts/admin-layout/AdminPageLayout";

const AgencyUsers = () => {
  const navigate = useNavigate();
  const { agency_id } = useParams();
  const [agencyData, setAgencyData] = useState([]);
  const [hasRecords, setHasRecords] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [page, setPage] = useState(1);
  const [listData, setListData] = useState();
  const [loading, setLoading] = useState(true);
  const [dataLoading, setDataLoading] = useState(true);
  const [advancedSearch, setAdvancedSearch] = useState({
    role: USER_ROLES.agency,
    agency_id,
  });

  useEffect(() => {
    const checkRecords = async () => {
      const resp = await UsersApis.getAllUsers({
        limit: true,
        page,
        advancedSearch,
      });
      setHasRecords(resp?.data?.results?.length > 0);
      setLoading(resp?.data?.results?.length > 0);
    };

    const getAgencyData = async () => {
      const resp = await AgencyApis.getAgencyById({ agency_id });
      resp?.success ? setAgencyData(resp?.data) : navigate(-1);
    };

    checkRecords();
    getAgencyData();
  }, []);

  useEffect(() => {
    !!hasRecords && AgencyUsersList();
  }, [hasRecords, page, advancedSearch]);

  // Get all agency's users records
  const AgencyUsersList = async () => {
    setDataLoading(true);
    let params = {
      sortKey: "first_name",
      limit: true,
      page,
      advancedSearch,
    };
    const resp = await UsersApis.getAllUsers(params);
    if (resp) {
      setDataLoading(false);
      setLoading(false);
    }
    setDataList(resp?.data?.results ?? []);
    setListData(resp?.data);
  };

  return (
    <AdminPageLayout>
      <Grid container sx={{ marginBottom: "1.5rem" }}>
        <Grid item xs={6} sm={7} md={8} lg={9} xl={10}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <GoBack />
            <Typography variant="h1" sx={{ margin: "0" }}>{`${
              agencyData?.name ? `${agencyData?.name}'s Users` : ""
            }`}</Typography>
          </Box>
        </Grid>
        <Grid item xs={6} sm={5} md={4} lg={3} xl={2}>
          <Button
            title="Add New User"
            variant="contained"
            sx={{ width: "100%", minHeight: "2rem" }}
            startIcon={<AddIcon sx={{ color: "#FFFFFF" }} />}
            disabled={agencyData?.is_active <= 0}
            onClick={() =>
              navigate(`${AllRoutes.AddNewAgencyUser.route}/${agency_id}`)
            }
          />
        </Grid>
      </Grid>

      <TableData
        caption={`${
          agencyData?.name ? `${agencyData?.name}'s Users` : "Agency's Users"
        }`}
        agencyData={agencyData}
        hasRecords={hasRecords}
        page={page}
        setPage={setPage}
        dataList={dataList}
        setDataList={setDataList}
        listData={listData}
        loading={loading}
        dataLoading={dataLoading}
        setAdvancedSearch={setAdvancedSearch}
      />
    </AdminPageLayout>
  );
};

export { AgencyUsers };
