/*
 * Cancel SAD/IVF Request
 */

import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { Grid, Box, Typography } from "@mui/material";

import { AttachmentApis, RequestApis } from "../../api";
import { Cancelation } from "../sad/constants";
import { AllRoutes } from "../../routes";
import { SadActionButtons } from "../sad/sad-user/components/ActionButtons";
import {
  CommonModal,
  GlobalLoader,
  TextInput,
  FilePicker,
  Tooltip,
  MandatoryStatement,
} from "../../components";
import { AdminPageLayout } from "../../layouts/admin-layout/AdminPageLayout";
import { RequestsHeader } from "../sad/sad-user/components/RequestsHeader";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const validationSchema = yup.object().shape({
  additional_recipient_email: yup.string().email("Invalid Email"),
  description: yup.string().required("Description is required"),
});

const User_CancelRequest = ({ type }) => {
  const navigate = useNavigate();
  const { id: application_id } = useParams();
  const isIVFPolicy = type === "ivf";
  const [updateModal, setUpdateModal] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [isDocUploading, setDocUploading] = useState(false);
  const [docRequired, setDocRequired] = useState("");
  const [loading, setLoading] = useState(true);
  const [files, setFiles] = useState([]);
  const [fileToBeDeleted, setFileToBeDeleted] = useState();
  const [isValidFile, setIsValidFile] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [attachment_id, setAttachment_id] = useState([]);
  const [labelled_as, setLabelled_as] = useState([]);

  const {
    register,
    setError,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      type_of_change: Cancelation,
      additional_recipient_email: "",
      description: "",
      has_cancel_request: 1,
    },
  });

  const openPdfInNewTab = (file) => {
    !!file?.url && window.open(file?.url, "_blank");
  };

  const handleFileUpload = async (newFile) => {
    setFiles((prev) => [...prev, newFile[0]]);
    setDocUploading(true);
    const formData = new FormData();
    formData.append("group", "documents");
    formData.append("labelled_as", application_id);
    formData.append("file", newFile?.[0]);
    const resp = await AttachmentApis.uploadAttachment({
      change_request_id: application_id,
      formData,
    });
    if (resp?.success) {
      const id = resp?.data?.id;
      const labelled = formData?.labelled_as || application_id;
      setLabelled_as((prev) => [...prev, labelled]);
      setAttachment_id((prev) => [...prev, id]);
      setDocRequired("");
    } else {
      setFiles([]);
    }
    setDocUploading(false);
  };

  const removePdf = async () => {
    const newfiles = files.filter((file, index) => index !== fileToBeDeleted);
    setFiles(newfiles);
    setShowDeleteModal(false);
    setTimeout(() => {
      document.getElementById("btn-upload-file").focus();
      document.getElementById("contained-button-file").value = "";
    }, 300);
  };

  const onSubmit = async (data) => {
    setButtonLoading("submit");
    const resp = await RequestApis.postChangeCancelReq({
      application_id,
      ...data,
    });
    if (resp) {
      setButtonLoading("");
    }
    if (resp?.success) {
      if (attachment_id.length > 0) {
        for (let i = 0; i < files.length; i++) {
          await RequestApis.attachChangeCancelReqDocs({
            attachment_id: attachment_id[i],
            labelled_as: labelled_as[i],
            change_request_id: resp?.data?.id,
          });
        }
      }
      setUpdateModal(true);
    } else {
      Object.keys(resp?.errors).forEach(function (key) {
        setError(key, { message: resp?.errors[key] });
      });
    }
  };

  return (
    <AdminPageLayout>
      <RequestsHeader
        type={type}
        header="Cancel Request"
        setLoading={setLoading}
      />
      {loading && <GlobalLoader loading={loading} />}

      <form onSubmit={handleSubmit(onSubmit)}>
        <MandatoryStatement />
        <Grid container columnSpacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextInput
              inputKey="additional_recipient_email"
              inputLabel="Email"
              required={false}
              disabled={buttonLoading}
              helperText="Note: This email address will also receive notifications about cancel request."
              register={register}
              registerChange={(e) => {
                setError(e.target.name, false);
              }}
              isError={errors?.additional_recipient_email}
              errorMsg={errors?.additional_recipient_email?.message}
            />
          </Grid>
        </Grid>
        <TextInput
          inputKey="description"
          inputLabel="Description"
          disabled={buttonLoading}
          helperText="Note: Use above space to include info pertaining to your cancel request."
          multiline
          rows={2}
          register={register}
          registerChange={(e) => {}}
          isError={errors?.description}
          errorMsg={errors?.description?.message}
        />

        <Box alignItems="center" marginY="2rem" mt={3}>
          <Typography marginBottom="1rem" id="lbl-upload-file" fontSize="14px">
            Please upload the supporting documents.
            <Tooltip tooltip="File can only be type of pdf / word" />
          </Typography>
          <FilePicker
            disabled={buttonLoading}
            files={files}
            setFiles={setFiles}
            setIsValidFile={setIsValidFile}
            openPdfInNewTab={openPdfInNewTab}
            setFileToBeDeleted={setFileToBeDeleted}
            setShowDeleteModal={setShowDeleteModal}
            handleFileUpload={(newFile) => handleFileUpload(newFile)}
            isDocUploading={isDocUploading}
            errorMsg={docRequired}
            helperText={
              isValidFile
                ? "Note: Two Files Max"
                : "Note: Only pdf and word file allowed"
            }
          />
        </Box>

        {/* Modal to confirm delete documents */}
        <CommonModal
          open={showDeleteModal}
          type="delete"
          onCancel={() => setShowDeleteModal(false)}
          confirmButtonLabel="Delete"
          onConfirm={removePdf}
          confirmButtonLoading={buttonLoading == "delete"}
          title="Are You Sure?"
          subTitle="Do you want to delete this file?"
        />

        <SadActionButtons
          onPressBack={() =>
            isIVFPolicy
              ? navigate(AllRoutes.IvfPoliciesPage.route)
              : navigate(AllRoutes.SadPoliciesPage.route)
          }
          confirmButtonLabel="Submit"
          buttonLoading={buttonLoading == "submit"}
          disableContinue={isDocUploading}
          disableBack={isDocUploading}
        />

        {/* Modal to confirm delete documents */}
        <CommonModal
          open={updateModal}
          type="success"
          onConfirm={() =>
            isIVFPolicy
              ? navigate(AllRoutes.IvfPoliciesPage.route)
              : navigate(AllRoutes.SadPoliciesPage.route)
          }
          title="Request submitted successfully!"
        />
      </form>
    </AdminPageLayout>
  );
};

export { User_CancelRequest };
