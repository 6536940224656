/*
 * Admin -> Wizard -> State Table -> Add/Edit State Fees/Tax
 */

import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Grid,
  Typography,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from "@mui/material";

import { StateFeeApis } from "../../../api/StateFeeApis";
import { UtilFunctions } from "../../../utils/CommonUtils";
import { SadActionButtons } from "../../sad/sad-admin/components/ActionButtons";
import { AdminPageLayout } from "../../../layouts/admin-layout/AdminPageLayout";
import {
  CommonModal,
  Dropdown,
  GlobalLoader,
  GoBack,
  TextInput,
} from "../../../components";

const formValues = { label: "", notes: "", fee_flat: "", fee_percent: "" };

const AddEditStateTax = () => {
  const navigate = useNavigate();
  const { fee_id, country_code, state_code } = useParams();
  const lookups = useSelector((state) => state.commonReducer.lookups);
  const [feeData, setFeeData] = useState(null);
  const [updateModal, setUpdateModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [isAmtPercent, setAmtPercent] = useState(0);

  const validationSchema = useMemo(() => {
    const requiredSchema = {
      label: Yup.string().required("Fee label is required"),
    };

    if (isAmtPercent) {
      requiredSchema["fee_percent"] = Yup.string().required(
        "Fee percent amount is required"
      );
    } else {
      requiredSchema["fee_flat"] = Yup.string().required(
        "Fee flat amount is required"
      );
    }

    return Yup.object(requiredSchema);
  }, [isAmtPercent]);

  useEffect(() => {
    const getFeeData = async () => {
      setLoading(true);
      const resp = await StateFeeApis.getStateFee({ fee_id });
      !!resp && setLoading(false);
      !!resp?.success && setFeeData(resp?.data);
    };
    !!fee_id && getFeeData();
  }, []);

  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setError,
    setValue,
    watch,
  } = useForm({
    defaultValues: formValues,
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (fee_id && feeData) {
      Object.keys(feeData).forEach((key) => {
        if (feeData?.hasOwnProperty(key)) {
          setValue(key, feeData[key]);
        }
      });
    }
    setAmtPercent(feeData?.fee_percent > 0);
  }, [feeData]);

  const onSubmit = async (data) => {
    let params = data;
    params = UtilFunctions.deleteKeys(params, [
      isAmtPercent ? "fee_flat" : "fee_percent",
    ]);
    const payload = { ...params, country_code, state_code, sort_order: 0 };
    let resp = null;
    setButtonLoading(true);
    if (fee_id) {
      resp = await StateFeeApis.updateStateFee({ fee_id, params: payload });
    } else {
      resp = await StateFeeApis.addStateFee(payload);
    }
    !!resp && setButtonLoading(false);
    if (resp?.success) {
      setUpdateModal(true);
    } else {
      Object.keys(resp?.errors).forEach(function (key) {
        setError(key, { message: resp?.errors[key] });
      });
    }
  };

  return (
    <AdminPageLayout>
      <Box sx={{ display: "flex", alignItems: "center", marginBottom: "1rem" }}>
        <GoBack />
        <Typography variant="h1" sx={{ margin: "0" }}>
          {fee_id ? "Edit State Fee" : "Add State Fee"}
        </Typography>
      </Box>

      {loading && <GlobalLoader loading={loading} />}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container columnSpacing={2} rowSpacing={2}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Dropdown
              label="Fee Label"
              id="label"
              disabled={buttonLoading}
              options={lookups?.state_fee_labels || []}
              value={watch("label")}
              register={register}
              isError={errors?.label?.message}
              errorMsg={errors.label?.message}
            />
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    id="isAmtPercent"
                    name="isAmtPercent"
                    disabled={buttonLoading}
                    checked={isAmtPercent}
                    value={isAmtPercent}
                    onChange={() => {
                      setAmtPercent(!isAmtPercent);
                      setValue("fee_percent", "");
                      setValue("fee_flat", "");
                    }}
                  />
                }
                label="Percentage"
              />
            </FormGroup>
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <TextInput
              inputKey={isAmtPercent ? "fee_percent" : "fee_flat"}
              inputLabel={`Fee ${isAmtPercent ? "Percent" : "Flat Amount"}`}
              autoComplete="off"
              register={register}
              disabled={buttonLoading}
              isError={
                isAmtPercent
                  ? errors?.fee_percent?.message
                  : errors?.fee_flat?.message
              }
              errorMsg={
                isAmtPercent
                  ? errors?.fee_percent?.message
                  : errors?.fee_flat?.message
              }
            />
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <TextInput
              inputKey="notes"
              inputLabel="Notes"
              multiline
              rows={3}
              required={false}
              register={register}
              disabled={buttonLoading}
              isError={errors?.notes?.message}
              errorMsg={errors?.notes?.message}
            />
          </Grid>
        </Grid>

        <SadActionButtons
          onCancel={() => {
            reset(fee_id ? feeData : formValues);
            setAmtPercent(fee_id && feeData ? feeData?.fee_percent > 0 : 0);
          }}
          {...(!fee_id && { updateButtonLabel: "Add Fee" })}
          buttonLoading={buttonLoading}
        />
      </form>

      <CommonModal
        open={updateModal}
        type="success"
        onConfirm={() => {
          setUpdateModal(false);
          navigate(-1);
        }}
        title={`State Fee ${fee_id ? "Updated" : "Added"} Successfully!`}
      />
    </AdminPageLayout>
  );
};

export { AddEditStateTax };
