/*
 * SAD Terms and Conditions
 */

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import {
  Box,
  Grid,
  Checkbox,
  Button,
  Typography,
  FormControlLabel,
} from "@mui/material";

import { SadApis } from "../../../../api";
import { AllRoutes } from "../../../../routes";
import { theme } from "../../../../styles/theme";
import { SadActionButtons } from "../components/ActionButtons";
import { formattedMDYDate } from "../../../../utils/CommonUtils";
import DownloadIcon from "../../../../layouts/images/download.svg";

const TermsAndConditions = ({ setActiveStep }) => {
  const navigate = useNavigate();
  const { id: application_id } = useParams();
  const termsData = useSelector((state) => state.sadReducer.sadTermCondition);
  const summaryData = useSelector((state) => state.sadReducer.sadSummary);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [isChecked, setIsChecked] = useState({
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
  });

  useEffect(() => {
    window?.scrollTo?.(0, 0);
  }, []);

  const saveTermConditions = async () => {
    setButtonLoading(true);
    const resp = await SadApis.updateApplication({
      application_id,
      params: { agreed_term_id: termsData?.id },
    });
    !!resp && setButtonLoading(false);
    resp?.success && setActiveStep(2);
  };

  // Add line breaks in data from API
  const formatApiData = () => {
    const formattedData = termsData?.content?.replace?.(/\n/g, "<br>");
    return <p dangerouslySetInnerHTML={{ __html: formattedData }} />;
  };

  // handling the download of certificate pdf
  const handleDownload = async () => {
    const pdfFilePath = `${process.env.PUBLIC_URL}/SAD_SAMPLE_COVID.pdf`;

    try {
      // Fetching  the PDF file as a blob
      const response = await fetch(pdfFilePath);
      const blob = await response.blob();

      // Creating a download link and trigger the download
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "sample_certificate.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  return (
    <>
      {/* Page content */}
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box
            sx={{
              fontSize: "0.8rem",
              lineHeight: "1.5rem",
              marginBottom: "0.8rem",
            }}
          >
            {formatApiData()}
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ marginBottom: "1rem" }}>
        <Box>
          <Typography
            sx={{
              fontSize: "1rem",
              fontWeight: "bold",
              color: theme.black,
              lineHeight: "1.5rem",
              marginBottom: "0.5rem",
            }}
          >
            Applicant agrees to the above stated terms, conditions and
            warranties as of the: {formattedMDYDate()}
          </Typography>
        </Box>
        <FormControlLabel
          control={
            <Checkbox
              checked={isChecked.checkbox1}
              disabled={!termsData}
              onChange={() =>
                setIsChecked({
                  ...isChecked,
                  checkbox1: !isChecked.checkbox1,
                })
              }
            />
          }
          label="By checking this box Applicant confirms their understanding and acknowledges the above stated coverage of terms and warranty."
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isChecked.checkbox2}
              disabled={!termsData}
              onChange={() =>
                setIsChecked({
                  ...isChecked,
                  checkbox2: !isChecked.checkbox2,
                })
              }
            />
          }
          label={
            <>
              <Typography sx={{ flexDirection: "row", display: "flex" }}>
                Applicant agrees to ALL stated terms, conditions and warranties
                as of this date:
                <Typography sx={{ fontWeight: "bold", marginLeft: "0.2rem" }}>
                  {formattedMDYDate()}
                </Typography>
              </Typography>
            </>
          }
        />
        <Box>
          <Button
            variant="outlined"
            color="primary"
            disableElevation
            size="large"
            sx={{
              borderRadius: "25px",
              color: theme.mistBlue,
              whiteSpace: "nowrap",
              border: `2px ${theme.grey} solid`,
              marginY: "0.5rem",
              "&:hover": { borderWidth: "2px" },
            }}
            onClick={handleDownload}
            startIcon={
              <img
                src={DownloadIcon}
                alt=""
                style={{ width: "24px", height: "24px" }}
              />
            }
          >
            Download Sample Certificate
          </Button>
        </Box>
        <FormControlLabel
          control={
            <Checkbox
              checked={isChecked.checkbox3}
              disabled={!termsData}
              onChange={() =>
                setIsChecked({
                  ...isChecked,
                  checkbox3: !isChecked.checkbox3,
                })
              }
            />
          }
          label="I acknowledge that the sample certificate(s) was available for me to download."
        />
      </Box>

      {/* Action Buttons */}
      <SadActionButtons
        onPressBack={() => setActiveStep(0)}
        OnSaveExit={() => navigate(AllRoutes.SadPoliciesPage.route)}
        OnPressContinue={saveTermConditions}
        disableContinue={Object.values(isChecked).some((val) => val === false)}
        buttonLoading={buttonLoading}
      />
    </>
  );
};

export { TermsAndConditions };
