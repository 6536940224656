/*
 * User -> IVF Cycles -> Table data
 */

import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  IconButton,
  Typography,
  Grid,
  Button,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";

import {
  AttachmentApis,
  IvfApis,
  PaymentApis,
  UsersApis,
} from "../../../../api";
import {
  ALLOWED_ACTIONS,
  DEBOUNCE_DELAY,
  INSURANCE_TYPES,
  USER_DATA,
} from "../../../../constants";
import {
  PaymentTypes,
  PolicyDocTypes,
  PolicyTypes,
  RequestTypes,
} from "../../../sad/constants";
import {
  CommonModal,
  Dropdown,
  GlobalLoader,
  Loader,
  NoRecords,
  RenderActionMenu,
  RenderCellInfo,
  RenderChip,
  RenderDocumentIcon,
  TextInput,
} from "../../../../components";
import {
  downloadBlobAsFile,
  formatString,
  formattedMDYDate,
} from "../../../../utils/CommonUtils";
import { AllRoutes } from "../../../../routes";
import { useDebounce, usePlansWithoutRiders } from "../../../../hooks";
import ReactTable from "../../../../React Table";
import { theme } from "../../../../styles/theme";
import { LocalStorageHelper } from "../../../../utils/HttpUtils";
import { setIvfSummary } from "../../../../redux/slices/globalSlice";

const {
  CONTINUE_SUBMIT_APPLICATION,
  PAYNOW,
  CHANGE_REQUEST,
  CANCEL_REQUEST,
  DOWNLOAD_ALL_DOCS,
} = ALLOWED_ACTIONS;
const { cancelRequest, changeRequest } = RequestTypes;

const IVFTableComponent = ({ caption }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const myData = JSON.parse(LocalStorageHelper.get(USER_DATA));
  const [clickable, setClickable] = useState([]);
  const [actionAnchorEl, setActionAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [hasRecords, setHasRecords] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [page, setPage] = useState(1);
  const [listData, setListData] = useState();
  const [searchText, setSearchText] = useState("");
  const [submittedBy, setSubmittedBy] = useState(myData?.id);
  const [orgUsers, setOrgUsers] = useState([]);
  const [showCRInfoPopup, setShowCRInfoPopup] = useState("");
  const [sortKey, setSortKey] = useState("");
  const [loading, setLoading] = useState(true);
  const [dataLoading, setDataLoading] = useState(true);
  const [sortOrder, setSortOrder] = useState(false);
  const [advancedSearch, setAdvancedSearch] = useState({
    created_by: myData?.id,
  });
  const [showDocumentModal, setShowDocumentModal] = useState(false);
  const [documentLink, setDocumentLink] = useState("");
  const [actionLoading, setActionLoading] = useState(false);
  const [hiddenTitle, setHiddenTitle] = useState("");
  const isOrgAdmin = myData?.is_agency_admin || myData?.is_clinic_admin;

  const { getIndemnityLabelByValue } = usePlansWithoutRiders(
    INSURANCE_TYPES.ivf
  );

  useEffect(() => {
    ivfDataList();
  }, [page, advancedSearch]);

  useEffect(() => {
    const checkRecords = async () => {
      const resp = await IvfApis.getAllPolicies({
        page,
        ...(isOrgAdmin &&
          submittedBy != myData?.id && {
            advancedSearch: {
              organization_policies: 1,
              created_by: myData?.id,
            },
          }),
      });
      setHasRecords(resp?.data?.results?.length > 0);
    };
    checkRecords();

    if (isOrgAdmin) {
      getUsersList();
    }
  }, []);

  const getUsersList = async () => {
    const params = { sortOrder: "", sortKey: "first_name", limit: false };
    const res = await UsersApis.getAllUsers(params);
    const formatList = !!res?.length
      ? res?.map?.((user) => {
          const { first_name, last_name, id } = user || {};
          return { label: `${first_name || ""} ${last_name || ""}`, value: id };
        })
      : [];
    setOrgUsers(formatList);
  };

  const createIVFApplicationId = async () => {
    dispatch(setIvfSummary([]));
    navigate(`${AllRoutes.IvfCyclePage.route}`);
  };

  // Get IVF records
  const ivfDataList = async () => {
    setDataLoading(true);
    const { created_by, ...rest } = advancedSearch ?? {};
    const params = {
      page,
      advancedSearch:
        isOrgAdmin && submittedBy != myData?.id
          ? { ...advancedSearch, organization_policies: 1, exclude_draft: 1 }
          : rest,
    };
    const resp = await IvfApis.getAllPolicies(params);
    setDataList(resp?.data?.results ?? []);
    setListData(resp?.data);
    if (resp) {
      setDataLoading(false);
      setLoading(false);
    }
  };

  const handleListFilter = (e, key) => {
    setAdvancedSearch((p) => ({ ...p, [key]: e }));
    setPage(1);
  };

  const debounceOnSearch = useDebounce(async (value) => {
    handleListFilter(value, "q");
  }, DEBOUNCE_DELAY);

  const createPayment = async (invoice_id) => {
    setActionLoading(true);
    try {
      const resp = await PaymentApis.createPayment({
        invoice_id: invoice_id,
        insurance_type: INSURANCE_TYPES.ivf,
      });
      !!resp && setActionLoading(false);
      !!resp?.success && (window.location = resp?.data?.url);
    } catch (error) {
      console.log("createPayment", error);
    }
  };

  const getPolicyDocs = async (application_id, policy_doc_id) => {
    setShowDocumentModal(true);
    setHiddenTitle(formatString(policy_doc_id));
    const resp = await AttachmentApis.getPolicyDoc({
      application_id,
      policy_doc_id,
    });
    if (resp) {
      setDocumentLink(resp);
    } else {
      setShowDocumentModal(false);
    }
  };

  const downloadDocs = async (application_id, policy_doc_id) => {
    setActionLoading(true);
    const resp = await AttachmentApis.downloadDocument({
      application_id,
      policy_doc_id,
      download: 1,
    });

    if (resp?.success && resp?.data) {
      downloadBlobAsFile(resp.data, `${resp?.filename || "all_docs.zip"}`);
      setActionLoading(false);
    } else {
      setActionLoading(false);
      console.error("Failed to download policy docs");
    }
  };

  const openActionDropDown = (e, rowData) => {
    const { id, allowed_actions, invoice } = rowData || {};

    const allowedActions = allowed_actions?.map((item) => ({
      text: item.label,
      route: async () => {
        if (item.value === CONTINUE_SUBMIT_APPLICATION) {
          navigate(`${AllRoutes.IvfCyclePage.route}/${id}`);
        } else if (item.value === PAYNOW) {
          await createPayment(invoice?.id);
        } else if (item.value === CHANGE_REQUEST) {
          setShowCRInfoPopup(changeRequest);
        } else if (item.value === CANCEL_REQUEST) {
          setShowCRInfoPopup(cancelRequest);
        } else if (item.value === DOWNLOAD_ALL_DOCS) {
          await downloadDocs(id, PolicyDocTypes.all);
        }
      },
    }));

    setClickable(allowedActions);
    setSelectedRow(rowData);
    setActionAnchorEl(e.currentTarget);
  };

  const closeActionDropDown = () => {
    // setSelectedRow(null);
    setActionAnchorEl(null);
    setClickable([]);
  };

  const tableColumns = [
    {
      id: "Actions",
      header: "Action",
      cell: ({ row }) => {
        const { allowed_actions } = row?.original ?? {};
        return (
          <IconButton
            disabled={!allowed_actions?.length}
            onClick={(e) => openActionDropDown(e, row.original)}
            aria-label={"More actions of " + row.original?.application_number}
          >
            <MoreHorizIcon />
          </IconButton>
        );
      },
    },
    {
      id: "Policy Status",
      header: ({ column }) => {
        return (
          <Button
            sx={{ fontWeight: "10", fontSize: "0.7rem" }}
            variant="ghost"
            onClick={() => setSortKey("status")}
          >
            Policy Status
          </Button>
        );
      },
      accessorKey: "status",
      cell: (info) => {
        const isSubmitted = !info.row.original?.alerts?.length;
        const status = info.getValue();
        const { underReview } = PolicyTypes;
        return (
          <RenderChip
            label={
              status === underReview
                ? isSubmitted
                  ? "Submitted"
                  : "Under Review"
                : undefined
            }
            status={status}
            color={
              status === underReview && isSubmitted ? "#0B756E" : undefined
            }
            backgroundColor={
              status === underReview && isSubmitted ? "#0E8C8426" : undefined
            }
          />
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Policy Number",
      header: ({ column }) => {
        return (
          <Button
            sx={{ fontWeight: "10", fontSize: "0.7rem" }}
            variant="ghost"
            onClick={() => {
              setSortOrder((prev) => !prev);
              setSortKey("application_number");
              column.toggleSorting(column.getIsSorted() === "asc");
            }}
          >
            Policy Number
            {column.getIsSorted() ? (
              column.getIsSorted() === "desc" ? (
                <ArrowDropDownIcon />
              ) : (
                <ArrowDropUpIcon />
              )
            ) : (
              ""
            )}
          </Button>
        );
      },
      accessorKey: "application_number",
      cell: (info) => (
        <Typography
          sx={{
            color: "#0A7691",
            whiteSpace: "nowrap",
            fontSize: "1rem",
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          {info.getValue()}
        </Typography>
      ),
      footer: (props) => props.column.id,
    },
    {
      id: "Coverage Type",
      header: ({ column }) => {
        return (
          <Button
            sx={{ fontWeight: "10", fontSize: "0.7rem" }}
            variant="ghost"
            onClick={() => {
              setSortOrder((prev) => !prev);
              setSortKey("coverage_type");
              column.toggleSorting(column.getIsSorted() === "asc");
            }}
          >
            Coverage Type
            {column.getIsSorted() ? (
              column.getIsSorted() === "desc" ? (
                <ArrowDropDownIcon />
              ) : (
                <ArrowDropUpIcon />
              )
            ) : (
              ""
            )}
          </Button>
        );
      },
      accessorKey: "coverage_type",
      cell: (info) => {
        if (info.getValue() == "EDO") {
          return (
            <Typography
              sx={{
                color: "black",
                whiteSpace: "nowrap",
                fontSize: "1rem",
                fontWeight: 500,
                textAlign: "center",
              }}
            >
              Donor Only
            </Typography>
          );
        } else if (info.getValue() === "RO") {
          return (
            <Typography
              sx={{
                color: "black",
                whiteSpace: "nowrap",
                fontSize: "1rem",
                fontWeight: 500,
                textAlign: "center",
              }}
            >
              Recipient Only
            </Typography>
          );
        } else if (info.getValue() === "EDR") {
          return (
            <Typography
              sx={{
                color: "black",
                whiteSpace: "nowrap",
                fontSize: "1rem",
                fontWeight: 500,
                textAlign: "center",
              }}
            >
              Donor and Recipient
            </Typography>
          );
        }
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Term Length",
      header: ({ column }) => {
        return (
          <Button
            sx={{ fontWeight: "10", fontSize: "0.7rem" }}
            variant="ghost"
            onClick={() => {
              setSortOrder((prev) => !prev);
              setSortKey("term_length");
              column.toggleSorting(column.getIsSorted() === "asc");
            }}
          >
            Term Length
            {column.getIsSorted() ? (
              column.getIsSorted() === "desc" ? (
                <ArrowDropDownIcon />
              ) : (
                <ArrowDropUpIcon />
              )
            ) : (
              ""
            )}
          </Button>
        );
      },
      accessorKey: "term_length",
      cell: (info) => (
        <Typography
          sx={{
            color: "black",
            whiteSpace: "nowrap",
            fontSize: "1rem",
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          {info?.getValue() ? info.getValue() + " Months" : ""}
        </Typography>
      ),
      footer: (props) => props.column.id,
    },
    {
      id: "Limit of Indemnity",
      header: ({ column }) => {
        return (
          <Button
            sx={{ fontWeight: "10", fontSize: "0.7rem" }}
            variant="ghost"
            onClick={() => {
              setSortOrder((prev) => !prev);
              setSortKey("idemnity_limit");
              column.toggleSorting(column.getIsSorted() === "asc");
            }}
          >
            Limit of Indemnity
            {column.getIsSorted() ? (
              column.getIsSorted() === "desc" ? (
                <ArrowDropDownIcon />
              ) : (
                <ArrowDropUpIcon />
              )
            ) : (
              ""
            )}
          </Button>
        );
      },
      accessorKey: "idemnity_limit",
      cell: (info) => {
        const { coverage_type, term_length } = info?.row?.original ?? {};
        return (
          <Typography
            sx={{
              color: "black",
              whiteSpace: "nowrap",
              fontSize: "1rem",
              fontWeight: 500,
              textAlign: "center",
            }}
          >
            {info?.getValue()
              ? getIndemnityLabelByValue(
                  coverage_type,
                  term_length,
                  info.getValue()
                )
              : ""}
          </Typography>
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Application Date",
      header: ({ column }) => {
        return (
          <Button
            sx={{ fontWeight: "10", fontSize: "0.7rem" }}
            variant="ghost"
            onClick={() => {
              setSortOrder((prev) => !prev);
              column.toggleSorting(column.getIsSorted() === "asc");
            }}
          >
            Application Date
            {column.getIsSorted() ? (
              column.getIsSorted() === "desc" ? (
                <ArrowDropDownIcon />
              ) : (
                <ArrowDropUpIcon />
              )
            ) : (
              ""
            )}
          </Button>
        );
      },
      accessorKey: "submitted_on",
      cell: (info) => (
        <Typography
          sx={{
            color: "black",
            whiteSpace: "nowrap",
            fontSize: "1rem",
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          {info.getValue() ? formattedMDYDate(info.getValue()) : ""}
        </Typography>
      ),
      footer: (props) => props.column.id,
    },
    {
      id: "Effective Date",
      header: ({ column }) => {
        return (
          <Button
            sx={{ fontWeight: "10", fontSize: "0.7rem" }}
            variant="ghost"
            onClick={() => {
              setSortOrder((prev) => !prev);
              column.toggleSorting(column.getIsSorted() === "asc");
            }}
          >
            Effective Date
            {column.getIsSorted() ? (
              column.getIsSorted() === "desc" ? (
                <ArrowDropDownIcon />
              ) : (
                <ArrowDropUpIcon />
              )
            ) : (
              ""
            )}
          </Button>
        );
      },
      accessorKey: "effective_from",
      cell: (info) => (
        <Typography
          sx={{
            color: "black",
            whiteSpace: "nowrap",
            fontSize: "1rem",
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          {info.getValue() ? formattedMDYDate(info.getValue()) : ""}
        </Typography>
      ),
      footer: (props) => props.column.id,
    },
    {
      id: "Expiration Date",
      header: ({ column }) => {
        return (
          <Button
            sx={{ fontWeight: "10", fontSize: "0.7rem" }}
            variant="ghost"
            onClick={() => {
              setSortOrder((prev) => !prev);
              column.toggleSorting(column.getIsSorted() === "asc");
            }}
          >
            Expiration Date
            {column.getIsSorted() ? (
              column.getIsSorted() === "desc" ? (
                <ArrowDropDownIcon />
              ) : (
                <ArrowDropUpIcon />
              )
            ) : (
              ""
            )}
          </Button>
        );
      },
      accessorKey: "effective_till",
      cell: (info) => (
        <Typography
          sx={{
            color: "black",
            whiteSpace: "nowrap",
            fontSize: "1rem",
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          {info.getValue() ? formattedMDYDate(info.getValue()) : ""}
        </Typography>
      ),
      footer: (props) => props.column.id,
    },
    {
      id: "Payment Date",
      accessorKey: "payment_date",
      header: ({ column }) => {
        return (
          <Button
            sx={{ fontWeight: "10", fontSize: "0.7rem" }}
            variant="ghost"
            onClick={() => {
              setSortOrder((prev) => !prev);
              setSortKey("payment_date");
              column.toggleSorting(column.getIsSorted() === "asc");
            }}
          >
            Payment Date
            {column.getIsSorted() ? (
              column.getIsSorted() === "desc" ? (
                <ArrowDropDownIcon />
              ) : (
                <ArrowDropUpIcon />
              )
            ) : (
              ""
            )}
          </Button>
        );
      },
      cell: (info) => {
        const { invoice } = info?.row?.original;
        const paymentDate = invoice?.payment_date;
        return (
          <RenderCellInfo
            text={paymentDate ? formattedMDYDate(paymentDate) : ""}
          />
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Total Payment",
      header: ({ column }) => {
        return (
          <Button
            sx={{ fontWeight: "10", fontSize: "0.7rem" }}
            variant="ghost"
            onClick={() => {
              setSortOrder((prev) => !prev);
              column.toggleSorting(column.getIsSorted() === "asc");
            }}
          >
            Total Payment
            {column.getIsSorted() ? (
              column.getIsSorted() === "desc" ? (
                <ArrowDropDownIcon />
              ) : (
                <ArrowDropUpIcon />
              )
            ) : (
              ""
            )}
          </Button>
        );
      },
      accessorKey: "Total Payment",
      cell: (info) => {
        const { invoice, alerts } = info.row.original ?? {};
        const canPayment = invoice?.id && !alerts?.length;
        const paymentStatus = invoice?.payment_status;
        const isPaid = Boolean(paymentStatus == PaymentTypes.paid);
        return (
          <Typography
            sx={{
              color: "black",
              whiteSpace: "nowrap",
              fontSize: "1rem",
              fontWeight: 500,
              display: canPayment ? "flex" : "none",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            ${isPaid ? invoice?.total : invoice?.total_without_card_charges}
          </Typography>
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Payment Status",
      header: ({ column }) => {
        return (
          <Button
            sx={{ fontWeight: "10", fontSize: "0.7rem" }}
            variant="ghost"
            onClick={() => {
              setSortOrder((prev) => !prev);
              column.toggleSorting(column.getIsSorted() === "asc");
            }}
          >
            Payment Status
            {column.getIsSorted() ? (
              column.getIsSorted() === "desc" ? (
                <ArrowDropDownIcon />
              ) : (
                <ArrowDropUpIcon />
              )
            ) : (
              ""
            )}
          </Button>
        );
      },
      accessorKey: "payment_status",
      cell: (info) => {
        const { invoice, alerts } = info.row.original ?? {};
        const canPayment = invoice?.id && !alerts?.length;
        const paymentStatus = invoice?.payment_status;
        const isPaid = Boolean(paymentStatus == PaymentTypes.paid);
        const Icon = isPaid ? CheckCircleIcon : AccessTimeFilledIcon;
        const color = isPaid ? "#0D847D" : "#C0580E";

        return (
          <Box
            display={canPayment ? "flex" : "none"}
            alignItems="center"
            justifyContent="center"
          >
            <Icon sx={{ fontSize: "1rem", color: color }} />
            <Typography
              sx={{
                fontSize: "1rem",
                color: color,
                marginLeft: "0.3rem",
              }}
            >
              {isPaid ? "Paid" : "Pending"}
            </Typography>
          </Box>
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Documents",
      header: "Documents",
      accessorKey: "Documents",
      cell: (info) => {
        const { allowed_policy_docs } = info.row.original ?? {};
        return (
          <Box sx={{ whiteSpace: "nowrap" }}>
            <Grid container spacing={4} alignItems="center">
              <Grid item>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  {allowed_policy_docs?.map?.((d) => (
                    <RenderDocumentIcon
                      key={d.label}
                      tooltip={d?.label}
                      policy_doc_id={d?.document_id}
                      info={info}
                      getPolicyDocs={getPolicyDocs}
                    />
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Box>
        );
      },
      footer: (props) => props.column.id,
    },
  ];

  return (
    <>
      {loading ? (
        <GlobalLoader loading={loading} />
      ) : (
        <>
          <Grid container spacing={2} mt={2}>
            {!isOrgAdmin && !hasRecords ? null : (
              <Grid item xs={3}>
                <TextInput
                  inputKey="search"
                  inputLabel="Search"
                  tooltip="Search by Application Number, Agency Name, Clinic Name, Applicant Name."
                  autoComplete="off"
                  margin="none"
                  required={false}
                  value={searchText}
                  onChange={(e) => {
                    debounceOnSearch(e.target.value);
                    setSearchText(e.target.value);
                  }}
                  sx={{ height: "2.5rem" }}
                  endAdornment={
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  }
                />
              </Grid>
            )}
            {!!isOrgAdmin && (
              <Grid item xs={3}>
                <Dropdown
                  size="small"
                  label="Submitted By"
                  id="created_by"
                  options={orgUsers || []}
                  required={false}
                  value={submittedBy}
                  onChange={(e) => {
                    setSubmittedBy(e.target.value);
                    handleListFilter(e.target.value, "created_by");
                  }}
                  margin="none"
                  sx={{ backgroundColor: theme.white }}
                />
              </Grid>
            )}
          </Grid>

          {dataList?.length > 0 ? (
            <Box>
              <ReactTable
                tableColumns={tableColumns}
                tableData={dataList}
                page={page}
                setPage={setPage}
                listData={listData}
                caption={caption}
                dataLoading={dataLoading}
              />
              <RenderActionMenu
                anchorEl={actionAnchorEl}
                handleClose={closeActionDropDown}
                actions={clickable}
                selectedRow={selectedRow}
              />
            </Box>
          ) : dataLoading ? (
            <Loader />
          ) : (
            <NoRecords
              {...(submittedBy == myData?.id &&
                !hasRecords && {
                  onPress: createIVFApplicationId,
                })}
            />
          )}
        </>
      )}

      {actionLoading && <GlobalLoader loading={actionLoading} />}

      {/* Documents modal */}
      <CommonModal
        open={showDocumentModal}
        onCancel={() => {
          setShowDocumentModal(false);
          setDocumentLink("");
          setHiddenTitle("");
        }}
        cancelButtonLabel="Close"
        visuallyHiddenTitle={hiddenTitle}
        maxWidth="md"
        fullWidth
      >
        {!documentLink ? (
          <Loader />
        ) : (
          <>
            <iframe
              src={documentLink}
              width="100%"
              height="500"
              style={{ display: !documentLink ? "none" : "block" }}
            />
          </>
        )}
      </CommonModal>

      <CommonModal
        open={!!showCRInfoPopup}
        type="info"
        onConfirm={() => {
          setShowCRInfoPopup("");
          if (selectedRow?.is_change_request_allowed) {
            if (showCRInfoPopup === changeRequest) {
              dispatch(setIvfSummary([]));
              navigate(
                `${AllRoutes.IvfChangeReqPage.route}/${selectedRow?.id}`
              );
            } else if (
              showCRInfoPopup === cancelRequest &&
              !selectedRow?.has_cancel_request
            ) {
              dispatch(setIvfSummary([]));
              navigate(
                `${AllRoutes.IvfCancelReqPage.route}/${selectedRow?.id}`
              );
            }
          }
        }}
        title="Information!"
        subTitle={
          showCRInfoPopup === cancelRequest && !!selectedRow?.has_cancel_request
            ? "Cancel Request Already Submitted!"
            : `New Life Agency will only accept ${
                showCRInfoPopup?.toLowerCase?.() || "request"
              }s up to 2 weeks from Medication start date, if approved by New Life Agency.`
        }
      />
    </>
  );
};

export { IVFTableComponent };
