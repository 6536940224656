import React, { useEffect, useState } from "react";
import { Accordion, CommonModal } from "../../../../../components";
import { Grid } from "@mui/material";
import DonorAndRecipient from "../DonorAndRecipient";
import DropdownIVF from "../common/Dropdown";
import { useSelector } from "react-redux";
import { CommonApis } from "../../../../../api";
import {
  useDebounce,
  usePlansWithoutRiders,
  usePlansWithRiders,
} from "../../../../../hooks";
import TextInputIvf from "../common/TextInput";
import {
  financialEmailTooltip,
  INSURANCE_TYPES,
  RESTRICTED_STATES,
} from "../../../../../constants";
import Recipient from "./Recipient";
import Donor from "./Donor";
import { constructStateList } from "../../../../../utils/CommonUtils";

const GeneralInformationForm = ({
  id,
  index,
  disabled = false,
  register,
  watch,
  setValue,
  errors,
  setError,
  application_id,
  setLoading,
  headingLevel,
}) => {
  const lookups = useSelector((state) => state.commonReducer.lookups);
  const [contractuallyState, setContractuallyState] = useState([]);
  const [coverageTypeValue, setCoverageTypeValue] = useState("");
  const [countryStateModal, setCountryStateModal] = useState({
    show: false,
    text: "",
  });
  const [isCLPCountryStateChanged, setCLPCountryStateChanged] = useState(false);
  const { uniqueTermLengths, getIndemnityLimits } = usePlansWithoutRiders(
    INSURANCE_TYPES.ivf,
    watch(`applications.${index}.coverage_type`)
  );
  const indemnityLimits = getIndemnityLimits(
    watch(`applications.${index}.coverage_type`),
    watch(`applications.${index}.term_length`)
  );
  const { getRiders } = usePlansWithRiders(INSURANCE_TYPES.ivf);
  const reproductiveRiders = getRiders(
    watch(`applications.${index}.term_length`)
  );

  const getContractuallyZipChanges = useDebounce(async (e) => {
    if (
      watch(`applications.${index}.contractually_liable_party_country_code`) ===
      "US"
    ) {
      setCLPCountryStateChanged(true);
    }
    // Remove any character that is not a letter or a digit from the input value
    const value = e.target.value.replace?.(/^ /, "")?.replace(/ +/g, " ");

    // Update the zipcode field with the sanitized value
    setValue(`applications.${index}.contractually_liable_party_zipcode`, value);

    setLoading(true);
    let resp;
    if (value?.length > 2) {
      resp = await CommonApis.getZipData({
        country_code:
          watch(
            `applications.${index}.contractually_liable_party_country_code`
          ) || "",
        zipcode: value,
      });
    }
    setLoading(false);
    setValue(
      `applications.${index}.contractually_liable_party_state_code`,
      resp?.[0]?.state_code || ""
    );
    setValue(
      `applications.${index}.contractually_liable_party_city`,
      resp?.[0]?.city || ""
    );
    setError(`applications.${index}.contractually_liable_party_city`, null);
  }, 1000);

  const getContractuallyStateList = async (value) => {
    const resp = await CommonApis.getZipData({
      country_code: value,
    });
    const getStates = constructStateList(resp);
    setContractuallyState(getStates);
  };

  useEffect(() => {
    getContractuallyStateList("US");
  }, []);

  useEffect(() => {
    getContractuallyStateList(
      watch(`applications.${index}.contractually_liable_party_country_code`)
    );
  }, [watch(`applications.${index}.contractually_liable_party_country_code`)]);

  // Show alert pop if Contractual Liable Party's restricted country/state is selected
  useEffect(() => {
    if (isCLPCountryStateChanged) {
      if (
        watch(
          `applications.${index}.contractually_liable_party_country_code`
        ) !== "US"
      ) {
        setCountryStateModal({ show: true, text: "Non US Country" });
      } else if (
        RESTRICTED_STATES?.includes?.(
          watch(`applications.${index}.contractually_liable_party_state_code`)
        )
      ) {
        setCountryStateModal({ show: true, text: "Restricted State" });
      } else {
        setCountryStateModal({ show: false, text: "" });
      }
    }
  }, [
    watch(`applications.${index}.contractually_liable_party_country_code`),
    watch(`applications.${index}.contractually_liable_party_state_code`),
  ]);

  return (
    <>
      <Accordion
        defaultExpanded={true}
        header="General Information"
        component={headingLevel}
        name={`general-information-${index}`}
      >
        {/* -- This textinput has no impact, it's created to fix auto-focus on first input field of form -- */}
        <TextInputIvf
          register={register}
          id={`applications.${index}.random`}
          name={`applications.${index}.random`}
          sx={{
            width: "0.1%",
            position: "absolute",
            zIndex: -1000,
            left: -1000,
            opacity: 0,
          }}
        />
        {/* --------------------------------------------------- */}

        <Grid container columnSpacing={3} sx={{ marginTop: "-1.5rem" }}>
          <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
            <DropdownIVF
              label="Coverage Type"
              id={`applications.${index}.coverage_type`}
              name={`applications.${index}.coverage_type`}
              disabled={disabled}
              options={lookups?.ivf_coverage_type_list}
              {...register(`applications.${index}.coverage_type`, {
                onChange: (e) => {
                  setValue(`applications.${index}.term_length`, null);
                  setValue(`applications.${index}.idemnity_limit`, null);
                  setValue(
                    `applications.${index}.reproductive_organ_loss_rider_option`,
                    null
                  );
                  if (`applications.${index}.coverage_type` === "EDO") {
                    setError(
                      `applications.${index}.recipient_has_cycled_before`,
                      null
                    );
                  } else {
                    setError(
                      `applications.${index}.donor_has_cycled_before`,
                      null
                    );
                  }
                  setCoverageTypeValue(e.target.value);
                },
              })}
              value={watch(`applications.${index}.coverage_type`)}
              isError={
                errors?.applications?.[index]?.["coverage_type"]?.message
              }
              errorMsg={
                errors?.applications?.[index]?.["coverage_type"]?.message
              }
            />
          </Grid>
          <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
            <DropdownIVF
              label="Term Length"
              id={`applications.${index}.term_length`}
              name={`applications.${index}.term_length`}
              disabled={
                !watch(`applications.${index}.coverage_type`) || disabled
              }
              options={uniqueTermLengths}
              value={watch(`applications.${index}.term_length`)}
              {...register(`applications.${index}.term_length`, {
                onChange: (e) => {
                  setValue(`applications.${index}.idemnity_limit`, null);
                  setValue(
                    `applications.${index}.reproductive_organ_loss_rider_option`,
                    null
                  );
                },
              })}
              isError={errors?.applications?.[index]?.["term_length"]?.message}
              errorMsg={errors?.applications?.[index]?.["term_length"]?.message}
            />
          </Grid>
          <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
            <DropdownIVF
              label="Limit of Indemnity"
              id={`applications.${index}.idemnity_limit`}
              name={`applications.${index}.idemnity_limit`}
              value={watch(`applications.${index}.idemnity_limit`)}
              disabled={
                disabled ||
                !watch(`applications.${index}.coverage_type`) ||
                !watch(`applications.${index}.term_length`)
              }
              tooltip="Maximum amount limit that would be paid under policy, for covered services/claims."
              options={indemnityLimits}
              {...register(`applications.${index}.idemnity_limit`, {
                onChange: () => {
                  setError(`applications.${index}.idemnity_limit`, null);
                },
              })}
              isError={
                errors?.applications?.[index]?.["idemnity_limit"]?.message
              }
              errorMsg={
                errors?.applications?.[index]?.["idemnity_limit"]?.message
              }
            />
          </Grid>
          <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
            <DropdownIVF
              label="Reproductive Organ Loss Rider"
              id={`applications.${index}.reproductive_organ_loss_rider`}
              name={`applications.${index}.reproductive_organ_loss_rider`}
              disabled={disabled}
              value={watch(
                `applications.${index}.reproductive_organ_loss_rider`
              )}
              options={lookups?.yes_no_list}
              {...register(
                `applications.${index}.reproductive_organ_loss_rider`,
                {
                  onChange: (e) => {
                    setError(
                      `applications.${index}.reproductive_organ_loss_rider`,
                      null
                    );
                    setValue(
                      `applications.${index}.reproductive_organ_loss_rider`,
                      e.target.value
                    );
                    setValue(
                      `applications.${index}.reproductive_organ_loss_rider_option`,
                      null
                    );
                    setValue(
                      `applications.${index}.optional_rider_intended_for`,
                      null
                    );
                  },
                }
              )}
              isError={
                errors?.applications?.[index]?.["reproductive_organ_loss_rider"]
                  ?.message
              }
              errorMsg={
                errors?.applications?.[index]?.["reproductive_organ_loss_rider"]
                  ?.message
              }
              tooltip="Endorsement will pay the benefit amount specified in the event that the donor/recipient has to undergo a Total or Subtotal/Partial Hysterectomy, an Oophorectomy or a Salpingectomy due to a medical condition caused directly by complications from IVF procedures/pregnancy and recommended by two independent medical practitioners."
            />
          </Grid>
          <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
            <DropdownIVF
              label="If Yes Select Coverage Limit"
              id={`applications.${index}.reproductive_organ_loss_rider_option`}
              name={`applications.${index}.reproductive_organ_loss_rider_option`}
              value={watch(
                `applications.${index}.reproductive_organ_loss_rider_option`
              )}
              disabled={
                disabled ||
                !watch(`applications.${index}.term_length`) ||
                !watch(`applications.${index}.reproductive_organ_loss_rider`)
              }
              options={reproductiveRiders}
              {...register(
                `applications.${index}.reproductive_organ_loss_rider_option`
              )}
              isError={
                errors?.applications?.[index]?.[
                  "reproductive_organ_loss_rider_option"
                ]?.message
              }
              errorMsg={
                errors?.applications?.[index]?.[
                  "reproductive_organ_loss_rider_option"
                ]?.message
              }
            />
          </Grid>
          {!!watch(`applications.${index}.reproductive_organ_loss_rider`) &&
            watch(`applications.${index}.coverage_type`) == "EDR" && (
              <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                <DropdownIVF
                  label="Optional Coverage Intended For"
                  id={`applications.${index}.optional_rider_intended_for`}
                  name={`applications.${index}.optional_rider_intended_for`}
                  disabled={disabled}
                  {...register(
                    `applications.${index}.optional_rider_intended_for`
                  )}
                  value={watch(
                    `applications.${index}.optional_rider_intended_for`
                  )}
                  options={lookups?.ivf_coverage_type_list}
                  isError={
                    errors?.applications?.[index]?.[
                      "optional_rider_intended_for"
                    ]?.message
                  }
                  errorMsg={
                    errors?.applications?.[index]?.[
                      "optional_rider_intended_for"
                    ]?.message
                  }
                  tooltip="When purchasing donor or recipient coverage the correct option will be auto selected. When purchasing donor and recipient combined coverage you can select to have the coverage applied to one or the other. If you select Donor & Recipient the correct amount will be calculated to cover both."
                />
              </Grid>
            )}

          {coverageTypeValue === "EDR" ? (
            <DonorAndRecipient
              id={id}
              index={index}
              disabled={disabled}
              register={register}
              coverageTypeSelected={coverageTypeValue}
              setValue={setValue}
              watch={watch}
              errors={errors}
              setError={setError}
              application_id={application_id}
            />
          ) : coverageTypeValue === "RO" ? (
            <Recipient
              id={id}
              index={index}
              disabled={disabled}
              register={register}
              coverageTypeSelected={coverageTypeValue}
              setValue={setValue}
              watch={watch}
              errors={errors}
              setError={setError}
              application_id={application_id}
            />
          ) : (
            <Donor
              id={id}
              index={index}
              disabled={disabled}
              register={register}
              coverageTypeSelected={coverageTypeValue}
              setValue={setValue}
              watch={watch}
              errors={errors}
              setError={setError}
              application_id={application_id}
            />
          )}
          <Grid
            item
            md={coverageTypeValue === "EDR" ? 12 : 6}
            sm={coverageTypeValue === "EDR" ? 12 : 6}
            xs={coverageTypeValue === "EDR" ? 12 : 6}
          >
            <DropdownIVF
              label="Who is Contractually Liable for this Policy?"
              id={`applications.${index}.contractually_liable_party`}
              name={`applications.${index}.contractually_liable_party`}
              disabled={disabled}
              options={lookups?.ivf_contractual_liable_options}
              value={watch(`applications.${index}.contractually_liable_party`)}
              {...register(`applications.${index}.contractually_liable_party`)}
              tooltip="The Contractually Liable party will appear on the certificate as the Insured Party. Please put the Contractually Liable Party’s Full Address below."
              isError={
                errors?.applications?.[index]?.["contractually_liable_party"]
                  ?.message
              }
              errorMsg={
                errors?.applications?.[index]?.["contractually_liable_party"]
                  ?.message
              }
            />
          </Grid>

          <Grid
            item
            md={coverageTypeValue === "EDR" ? 12 : 6}
            sm={coverageTypeValue === "EDR" ? 12 : 6}
            xs={coverageTypeValue === "EDR" ? 12 : 6}
            mb={2}
          >
            <TextInputIvf
              type="text"
              id={`applications.${index}.contractually_liable_party_first_name`}
              name={`applications.${index}.contractually_liable_party_first_name`}
              label="Full Name of Contractually Liable Party"
              disabled={disabled}
              sx={{ marginTop: "16px", width: "100%" }}
              register={register}
              setError={setError}
              isError={
                errors?.applications?.[index]?.[
                  "contractually_liable_party_first_name"
                ]?.message
              }
              errorMsg={
                errors?.applications?.[index]?.[
                  "contractually_liable_party_first_name"
                ]?.message
              }
            />
          </Grid>
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            // sx={{ padding: "10px" }}
          >
            <TextInputIvf
              register={register}
              setError={setError}
              type="text"
              id={`applications.${index}.contractually_liable_party_address_line_1`}
              name={`applications.${index}.contractually_liable_party_address_line_1`}
              disabled={disabled}
              helperText="Note: P.O. Boxes are not allowed. Please enter a full street address."
              label="Street Address"
              sx={{ width: "100%" }}
              isError={
                errors?.applications?.[index]?.[
                  "contractually_liable_party_address_line_1"
                ]?.message
              }
              errorMsg={
                errors?.applications?.[index]?.[
                  "contractually_liable_party_address_line_1"
                ]?.message
              }
            />
          </Grid>
          <Grid
            item
            xl={3}
            lg={3}
            md={6}
            sm={12}
            xs={12}
            // sx={{ padding: "10px" }}
          >
            <DropdownIVF
              label="Country"
              id={`applications.${index}.contractually_liable_party_country_code`}
              name={`applications.${index}.contractually_liable_party_country_code`}
              disabled={disabled}
              value={watch(
                `applications.${index}.contractually_liable_party_country_code`
              )}
              options={lookups?.countries_list || []}
              {...register(
                `applications.${index}.contractually_liable_party_country_code`,
                {
                  onChange: (e) => {
                    setCLPCountryStateChanged(true);
                    getContractuallyStateList(e.target.value);
                    setValue(
                      `applications.${index}.contractually_liable_party_zipcode`,
                      ""
                    );
                    setValue(
                      `applications.${index}.contractually_liable_party_state_code`,
                      null
                    );
                    setValue(
                      `applications.${index}.contractually_liable_party_city`,
                      ""
                    );
                  },
                }
              )}
            />
          </Grid>
          <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
            <TextInputIvf
              id={`applications.${index}.contractually_liable_party_zipcode`}
              name={`applications.${index}.contractually_liable_party_zipcode`}
              label="ZIP Code"
              disabled={disabled}
              autoComplete="off"
              maxLength={9}
              register={register}
              onRegisterChange={getContractuallyZipChanges}
              setError={setError}
              isError={
                errors?.applications?.[index]?.[
                  "contractually_liable_party_zipcode"
                ]?.message
              }
              errorMsg={
                errors?.applications?.[index]?.[
                  "contractually_liable_party_zipcode"
                ]?.message
              }
              InputLabelProps={{ shrink: true }}
              sx={{ marginTop: "16px", width: "100%" }}
            />
          </Grid>
          <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
            <DropdownIVF
              id={`applications.${index}.contractually_liable_party_state_code`}
              name={`applications.${index}.contractually_liable_party_state_code`}
              label="State"
              disabled={disabled}
              options={contractuallyState || []}
              value={watch(
                `applications.${index}.contractually_liable_party_state_code`
              )}
              {...register(
                `applications.${index}.contractually_liable_party_state_code`,
                {
                  onChange: () => {
                    setCLPCountryStateChanged(true);
                  },
                }
              )}
              isError={
                errors?.applications?.[index]?.[
                  "contractually_liable_party_state_code"
                ]?.message
              }
              errorMsg={
                errors?.applications?.[index]?.[
                  "contractually_liable_party_state_code"
                ]?.message
              }
            />
          </Grid>
          <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
            <TextInputIvf
              disabled={disabled}
              register={register}
              setError={setError}
              isError={
                errors?.applications?.[index]?.[
                  "contractually_liable_party_city"
                ]?.message
              }
              errorMsg={
                errors?.applications?.[index]?.[
                  "contractually_liable_party_city"
                ]?.message
              }
              type="text"
              sx={{ marginTop: "16px", width: "100%" }}
              label="City"
              id={`applications.${index}.contractually_liable_party_city`}
              name={`applications.${index}.contractually_liable_party_city`}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item xl={12} lg={12} sm={12} xs={12} md={12}>
            <TextInputIvf
              disabled={disabled}
              setError={setError}
              register={register}
              type="text"
              required={false}
              id={`applications.${index}.financial_email`}
              name={`applications.${index}.financial_email`}
              label="Additional Email Address(s) that need a copy of the Invoice and Application"
              sx={{ width: "100%", marginTop: "16px" }}
              InputLabelProps={{ shrink: true }}
              tooltip={financialEmailTooltip}
              isError={
                errors?.applications?.[index]?.["financial_email"]?.message
              }
              errorMsg={
                errors?.applications?.[index]?.["financial_email"]?.message
              }
            />
          </Grid>
        </Grid>
      </Accordion>

      {/* Warning modal if Contractual Liable Party's non US country or restricted state selected */}
      <CommonModal
        open={countryStateModal.show}
        type="warning"
        onConfirm={() => {
          setCountryStateModal({ show: false, text: "" });
          setCLPCountryStateChanged(false);
        }}
        title="Please Note"
        subTitle={`Due to ${countryStateModal.text}, this application will need to be reviewed before approval. If you have any questions, please contact your broker.`}
      />
    </>
  );
};

export default GeneralInformationForm;
