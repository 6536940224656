/*
 * Policy Extension
 */

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@mui/material";

import {
  GlobalLoader,
  MandatoryStatement,
  Dropdown,
  TextInput,
  DatePicker,
} from "../../../components";
import { AllRoutes } from "../../../routes";
import { RequestsHeader } from "../sad-user/components/RequestsHeader";
import { SadActionButtons } from "../sad-user/components/ActionButtons";
import { AdminPageLayout } from "../../../layouts/admin-layout/AdminPageLayout";
import { PolicyExtensionApis } from "../../../api";

const validationSchema = Yup.object({
  extension_period: Yup.string().required("Extension Period field is required"),
  total_transfers: Yup.string().required(
    "How many transfers were completed field is required"
  ),
  transfer_lead_to_pregnancy: Yup.string().required(
    "Did any transfer lead to a pregnancy that resulted in a 6 week ultrasound field is required"
  ),
  confirmation_of_pregnancy_date: Yup.string().required(
    "Confirmation of Pregnancy Date field is required"
  ),
  expected_delivery_date: Yup.string().required(
    "Expected date of delivery field is required"
  ),
}).required();

const PolicyExtension = () => {
  const navigate = useNavigate();
  const { id: application_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const lookups = useSelector((state) => state.commonReducer.lookups);
  const { sad_extension_periods, yes_no_list } = lookups ?? {};

  const {
    register,
    setError,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      extension_period: null,
      total_transfers: null,
      transfer_lead_to_pregnancy: null,
      confirmation_of_pregnancy_date: "",
      expected_delivery_date: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    setButtonLoading(true);
    let payload = data;
    payload.extension_period = Number(payload.extension_period);
    payload.total_transfers = Number(payload.total_transfers);
    payload.transfer_lead_to_pregnancy = Number(
      payload.transfer_lead_to_pregnancy
    );
    const resp = await PolicyExtensionApis.postPolicyExtension({
      application_id,
      ...payload,
    });

    !!resp && setButtonLoading(false);

    if (resp?.success) {
      navigate(`${AllRoutes.PolicyExtensionSummary.route}/${application_id}`, {
        replace: true,
      });
    }
  };

  return (
    <AdminPageLayout>
      <RequestsHeader
        isIVFPolicy={false}
        header="Policy Extension"
        setLoading={setLoading}
      />
      {loading && <GlobalLoader loading={loading} />}

      <form onSubmit={handleSubmit(onSubmit)}>
        <MandatoryStatement />
        <Grid container columnSpacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Dropdown
              label="Extension Period"
              id="extension_period"
              register={register}
              disabled={buttonLoading}
              options={sad_extension_periods}
              value={watch("extension_period")}
              isError={errors?.extension_period?.message}
              errorMsg={errors?.extension_period?.message}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <TextInput
              inputKey="total_transfers"
              inputLabel="How many transfers were completed?"
              autoComplete="off"
              disabled={buttonLoading}
              register={register}
              isError={errors?.total_transfers?.message}
              errorMsg={errors?.total_transfers?.message}
              type="number"
              onFocus={(e) =>
                e.target.addEventListener(
                  "wheel",
                  function (e) {
                    e.preventDefault();
                  },
                  { passive: false }
                )
              }
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Dropdown
              label="Did any transfer lead to a pregnancy that resulted in a 6 week ultrasound?"
              id="transfer_lead_to_pregnancy"
              register={register}
              disabled={buttonLoading}
              options={yes_no_list ?? []}
              value={watch("transfer_lead_to_pregnancy")}
              isError={errors?.transfer_lead_to_pregnancy?.message}
              errorMsg={errors?.transfer_lead_to_pregnancy?.message}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <DatePicker
              id="confirmation_of_pregnancy_date"
              label="What was the Confirmation of Pregnancy Date?"
              disabled={buttonLoading}
              {...(watch("confirmation_of_pregnancy_date") && {
                value: dayjs(watch("confirmation_of_pregnancy_date")),
              })}
              onChange={(newValue) => {
                setValue("confirmation_of_pregnancy_date", newValue);
                setError("confirmation_of_pregnancy_date", null);
              }}
              isError={errors?.confirmation_of_pregnancy_date?.message}
              errorMsg={errors?.confirmation_of_pregnancy_date?.message}
            />
          </Grid>
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            sx={{ marginBottom: "1rem" }}
          >
            <DatePicker
              id="expected_delivery_date"
              label="What is your expected date of delivery?"
              disabled={buttonLoading}
              {...(watch("expected_delivery_date") && {
                value: dayjs(watch("expected_delivery_date")),
              })}
              onChange={(newValue) => {
                setValue("expected_delivery_date", newValue);
                setError("expected_delivery_date", null);
              }}
              isError={errors?.expected_delivery_date?.message}
              errorMsg={errors?.expected_delivery_date?.message}
            />
          </Grid>
        </Grid>

        <SadActionButtons
          onPressBack={() => navigate(-1)}
          buttonLoading={buttonLoading}
        />
      </form>
    </AdminPageLayout>
  );
};

export { PolicyExtension };
