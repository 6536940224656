/*
 * ADMIN ADD-NEW-USER
 */

import React from "react";

import { UserForm } from "../../components";

const AddNewUser = () => {
  return <UserForm />;
};

export { AddNewUser };
