/*
 * Common left action-menu component for all grids
 */

import React from "react";
import { Menu, MenuItem } from "@mui/material";

import { Tooltip } from "../Tooltip";
import { strings } from "../../constants";
import { RequestTypes } from "../../pages/sad/constants";

const { downloadAllDocuments } = RequestTypes;

const RenderActionMenu = ({
  anchorEl,
  handleClose = () => {},
  actions = [],
  selectedRow = {},
  anchorOrigin = true,
  transformOrigin = true,
  sx,
}) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      {...(anchorOrigin && {
        anchorOrigin: { vertical: "top", horizontal: "left" },
      })}
      {...(transformOrigin && {
        transformOrigin: { vertical: "top", horizontal: "left" },
      })}
    >
      {actions?.map((action, i) => {
        const { text = "", route = () => {} } = action ?? {};
        const { docs_being_generated, email_verified_at, is_active } =
          selectedRow ?? {};

        const showTooltip =
          docs_being_generated && text == downloadAllDocuments;

        const disableActions =
          ((text === strings.editBroker ||
            text === strings.editAgency ||
            text === strings.editClinic) &&
            is_active <= 0) ||
          (text === strings.loginAsThisUser && !email_verified_at);
        return (
          <Tooltip
            key={i}
            title={showTooltip ? "Docs are being generated" : ""}
            placement="right"
          >
            <MenuItem
              key={action.text}
              sx={{
                borderBottom:
                  i === actions?.length - 1 ? "none" : "1px solid #ddd",
                ...sx,
              }}
              onClick={() => {
                if (text !== downloadAllDocuments || !docs_being_generated) {
                  handleClose();
                  route();
                }
              }}
              disabled={Boolean(disableActions)}
            >
              {text || ""}
            </MenuItem>
          </Tooltip>
        );
      })}
    </Menu>
  );
};

export { RenderActionMenu };
