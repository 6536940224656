/*
 * Common Modal for all info's
 */

import React from "react";
import {
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "../layouts/images/Warning.svg";
import DeleteIcon from "../layouts/images/Delete-Icon.svg";
import CheckIcon from "../layouts/images/Check.svg";
import SomethingWentWrongIcon from "../layouts/images/smth.png";
import { theme } from "../styles/theme";
import { Button } from "./Button";

const style = {
  bgcolor: "background.paper",
  textAlign: "center",
  borderRadius: "5px",
  boxShadow: 24,
  p: 2,
};

const useStyles = makeStyles((theme) => ({
  imageIcon: {
    width: "5rem",
  },
  imageWrapper: {
    width: "5rem",
    height: "5rem",
    margin: "auto",
    marginBottom: "0.5rem",
  },
}));

const CommonModal = ({
  type = "",
  title,
  subTitle,
  subTitle2,
  visuallyHiddenTitle,
  open,
  cancelButtonLabel = "Cancel",
  onCancel,
  confirmButtonLabel = "Okay",
  confirmButtonLoading,
  disableConfirmButton = false,
  onConfirm,
  children,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      scroll="body"
      {...rest}
    >
      <Box sx={style}>
        {!!type && (
          <>
            {type === "info" ? (
              <InfoIcon sx={{ color: "#0A7691", fontSize: "4rem" }} />
            ) : (
              <Box className={classes.imageWrapper}>
                <img
                  src={
                    type === "success"
                      ? CheckIcon
                      : type === "warning"
                      ? WarningIcon
                      : type === "delete"
                      ? DeleteIcon
                      : type === "cancel"
                      ? SomethingWentWrongIcon
                      : null
                  }
                  alt={""}
                  className={classes.imageIcon}
                />
              </Box>
            )}
          </>
        )}

        {!!title && (
          <DialogTitle
            sx={{ fontWeight: "bold", fontSize: "1.2rem", padding: "0.2rem" }}
            id="alert-dialog-title"
          >
            <div dangerouslySetInnerHTML={{ __html: title }} />
          </DialogTitle>
        )}

        {!!visuallyHiddenTitle && (
          <DialogTitle id="alert-dialog-title" className="hidden-visually">
            <span dangerouslySetInnerHTML={{ __html: visuallyHiddenTitle }} />
          </DialogTitle>
        )}

        <DialogContent id="alert-dialog-description" sx={{ color: "#000" }}>
          {!!subTitle &&
          typeof subTitle === "object" &&
          subTitle.text &&
          subTitle.link &&
          subTitle.text2 &&
          subTitle.text3 ? (
            <Typography sx={{ mt: 1, mb: 1, fontSize: "0.9rem" }}>
              {subTitle.text}
              {!!subTitle.link && (
                <>
                  <a
                    href={subTitle.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      color: theme.mistBlue,
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    {subTitle.text2 || "our support"}
                  </a>
                  <span>{subTitle.text3}</span>
                </>
              )}
            </Typography>
          ) : (
            <Typography sx={{ mt: 1, mb: 1, fontSize: "0.9rem" }}>
              <div dangerouslySetInnerHTML={{ __html: subTitle }} />
            </Typography>
          )}
          {!!subTitle2 && (
            <Typography
              sx={{
                whiteSpace: "nowrap",
                fontWeight: "bold",
                fontSize: "1rem",
                marginY: "1rem",
              }}
            >
              {subTitle2}
            </Typography>
          )}
          {children}
        </DialogContent>

        <DialogActions sx={{ justifyContent: "center" }}>
          {!!onCancel && (
            <Button
              variant="outlined"
              size="large"
              title={cancelButtonLabel}
              sx={{
                color: theme.grey,
                marginRight: onConfirm ? "1rem" : "0",
                border: `1px ${theme.grey} solid`,
              }}
              onClick={onCancel}
              autoFocus
            />
          )}
          {!!onConfirm && (
            <Button
              variant="contained"
              onClick={onConfirm}
              size="large"
              loading={confirmButtonLoading}
              disabled={confirmButtonLoading || disableConfirmButton}
              title={confirmButtonLabel}
              autoFocus={!onCancel ? true : false}
            />
          )}
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export { CommonModal };
