import React from "react";
import GeneralInformationForm from "./components/GeneralInformationForm";
import IVFClinicForm from "./components/IVFClinicForm";
import AgencyDetailForm from "./components/AgencyDetailForm";

const AddNewApplications = ({
  id,
  index,
  disabled = false,
  register,
  watch,
  setValue,
  errors,
  setError,
  setLoading,
  application_id,
  setUnverifiedOrgModal,
  headingLevel,
}) => {
  return (
    <>
      {/* General Information */}
      <GeneralInformationForm
        id={id}
        index={index}
        disabled={disabled}
        register={register}
        watch={watch}
        setValue={setValue}
        errors={errors}
        setError={setError}
        application_id={application_id}
        setLoading={setLoading}
        headingLevel={headingLevel}
      />

      {/* IVF Clinic Details */}
      <IVFClinicForm
        index={index}
        disabled={disabled}
        register={register}
        watch={watch}
        setValue={setValue}
        errors={errors}
        setError={setError}
        setLoading={setLoading}
        setUnverifiedOrgModal={setUnverifiedOrgModal}
        headingLevel={headingLevel}
      />

      {/* Agency Details */}
      <AgencyDetailForm
        index={index}
        disabled={disabled}
        register={register}
        watch={watch}
        setValue={setValue}
        errors={errors}
        setError={setError}
        setLoading={setLoading}
        setUnverifiedOrgModal={setUnverifiedOrgModal}
        headingLevel={headingLevel}
      />
    </>
  );
};

export default AddNewApplications;
