/*
 * SAD form
 */

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";

import { Summary } from "./Summary";
import { Payment } from "./Payment";
import { UserAgreement } from "./UserAgreement";
import { ApplicationDetails } from "./ApplicationDetails";
import { TermsAndConditions } from "./TermsAndConditions";
import {
  GlobalLoader,
  MandatoryStatement,
  Stepper,
} from "../../../../components";
import { AdminPageLayout } from "../../../../layouts/admin-layout/AdminPageLayout";
import { setSadSummary } from "../../../../redux/slices/globalSlice";
import { InstructionNote } from "../../../../components";
import { SadApis } from "../../../../api";
import { AllRoutes } from "../../../../routes";
import { PaymentTypes, PolicyTypes } from "../../constants";
import { INSURANCE_TYPES, SAD_CR_VALIDITY } from "../../../../constants";

const steps = [
  "Application Details",
  "Terms & Conditions",
  "User Agreement",
  "Summary",
  "Payment",
];

const SadForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const lookups = useSelector((state) => state.commonReducer.lookups);
  const { id: application_id } = useParams();
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const { change_request_charges } = lookups ?? {};

  useEffect(() => {
    const getApplicationID = async () => {
      if (!application_id) {
        setLoading(true);
        const payload = { insurance_type: INSURANCE_TYPES.sad };
        const resp = await SadApis.createApplicationId(payload);
        !resp?.success && setLoading(false);
        !!resp?.data?.id &&
          navigate(`${AllRoutes.SadForm.route}/${resp?.data?.id}`, {
            replace: true,
          });
      }

      if (application_id) {
        setLoading(true);
        getFormData();
      }
    };
    getApplicationID();
  }, [application_id]);

  const getFormData = async () => {
    const resp = await SadApis.getApplicationData({ application_id });

    if (resp && resp?.success && resp?.data?.agency_state_code) {
      setLoading(true);
    } else {
      setLoading(false);
    }

    if (resp?.success) {
      const { status, alerts, invoice } = resp?.data ?? {};
      const { payment_status } = invoice ?? {};
      const { certificate_issued, underReview } = PolicyTypes;
      const { paid, notAttempted } = PaymentTypes;

      if (status === underReview) {
        if (payment_status === paid) {
          navigate(AllRoutes.SadPoliciesPage.route);
        } else if (!alerts?.length && payment_status == notAttempted) {
          setActiveStep(4);
        } else if (alerts?.length > 0) {
          navigate(AllRoutes.SadPoliciesPage.route);
        }
      } else if (
        status === certificate_issued &&
        payment_status == notAttempted
      ) {
        setActiveStep(4);
      }
    } else if (resp?.code == "404") {
      navigate(AllRoutes.Error404Page.route);
    } else {
      navigate(AllRoutes.SadPoliciesPage.route);
    }
    dispatch(setSadSummary(resp?.data));
  };

  return (
    <AdminPageLayout>
      <Typography variant="h1">Surrogate Accidental Death Insurance</Typography>

      {activeStep === 0 && (
        <InstructionNote
          note={`Please note, If you submit and pay for this policy you are applying for and then need to amend/change any part of your completed applicatIon, please submit a change request. A $${
            change_request_charges || ""
          } change fee applies to any and all changes if the certificate has been issued. New Life Agency will only accept change requests up to ${SAD_CR_VALIDITY} days from application date, if approved by New Life Agency.`}
          width={"100%"}
          alignItems={"start"}
        />
      )}

      {(activeStep === 0 || activeStep === 2) && <MandatoryStatement />}

      {/* Step indicator */}
      <Stepper
        activeStep={activeStep}
        marginY="2rem"
        steps={steps}
        stepperWidth="100%"
      />
      {loading && <GlobalLoader loading={loading} />}

      {/* FORM STEP WISE CONTENT */}
      {activeStep === 0 && (
        <ApplicationDetails
          loading={loading}
          setLoading={setLoading}
          setActiveStep={setActiveStep}
        />
      )}
      {activeStep === 1 && <TermsAndConditions setActiveStep={setActiveStep} />}
      {activeStep === 2 && <UserAgreement setActiveStep={setActiveStep} />}
      {activeStep === 3 && <Summary setActiveStep={setActiveStep} />}
      {activeStep === 4 && <Payment setLoading={setLoading} />}
    </AdminPageLayout>
  );
};

export { SadForm };
